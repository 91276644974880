import { Box, Grid, Typography, useTheme } from "@mui/joy";
import { useTranslation } from "react-i18next";
import CardService from "./services/card";

const Services = () => {

    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <>
            <Box sx={{ position: "relative", }}>
                <Box m={0} p={0} pt={{ xs: 8, md: 0 }} display="flex" justifyContent="center" alignItems="center" sx={{ width: "100vw", height: { xs: "100%", sm: "100%" }, backgroundColor: theme.vars.palette.background.body }}>
                    <Box>
                        <Box mt="180px" display={{ xs: "none", lg: "block" }}>
                            <Grid container mt={6} columns={8} sx={{ flexGrow: 1 }}>
                                <Grid pl={8} sm={4}>
                                    <Box>
                                        <Typography mr={1} fontFamily="Raleway" sx={{ color: "white", fontWeight: 700, fontSize: "48px", textAlign: "start" }}>
                                            {t('landing.tailoredServicesTitle')}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography mr={1} fontFamily="Raleway" sx={{ color: "white", fontWeight: 700, fontSize: "24px", textAlign: "start" }}>
                                            {t('landing.tailoredServicesSubtitle')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid sm={4}>
                                    <Box sx={{ maxWidth: "750px" }}>
                                        <Typography mr={1} fontFamily="Raleway" sx={{ color: "white", fontWeight: 500, fontSize: "20px", textAlign: "start" }}>
                                            {t('landing.servicesDescription1')}
                                        </Typography>
                                    </Box>
                                    <Box mt={2} sx={{ maxWidth: "750px" }}>
                                        <Typography mr={1} fontFamily="Raleway" sx={{ color: "white", fontWeight: 500, fontSize: "20px", textAlign: "start" }}>
                                            {t('landing.servicesDescription2')}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box display={{ xs: "block", lg: "none" }} pl={{ xs: 0, md: 8 }} pt={{ xs: 0, md: 8 }}>
                            <Grid mx={4} container columns={1} sx={{ flexGrow: 1 }}>
                                <Grid xs={1}>
                                    <Box>
                                        <Typography mr={1} fontFamily="Raleway" sx={{ color: "white", fontWeight: 700, fontSize: "24px", textAlign: "start" }}>
                                            {t('landing.tailoredServicesTitle')}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography mr={1} fontFamily="Raleway" sx={{ color: "white", fontWeight: 700, fontSize: "16px", textAlign: "start" }}>
                                            {t('landing.tailoredServicesSubtitle')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid xs={1}>
                                    <Box mt={4}>
                                        <Typography mr={1} fontFamily="Raleway" sx={{ color: "white", fontWeight: 500, fontSize: "14px", textAlign: "start" }}>
                                            {t('landing.servicesDescription1')}
                                        </Typography>
                                    </Box>
                                    <Box mt={1}>
                                        <Typography mr={1} fontFamily="Raleway" sx={{ color: "white", fontWeight: 500, fontSize: "14px", textAlign: "start" }}>
                                            {t('landing.servicesDescription2')}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box display="flex" justifyContent={{ xs: "start"}} mt={{ xs: 8, lg: 12 }} sx={{ overflowX: "auto", width: "100vw"}}>
                            <CardService
                                imageSrc="/images/services/services_1.png"
                                title={t('landing.uxDesign')}
                                backgroundColor={theme.vars.palette.background.commonWhite}
                            />
                            <CardService
                                imageSrc="/images/services/services_2.png"
                                title={t('landing.uiDesign')}
                                backgroundColor={theme.vars.palette.primary['100']}
                            />
                            <CardService
                                imageSrc="/images/services/services_3.png"
                                title={t('landing.branding')}
                                backgroundColor={theme.vars.palette.background.commonWhite}
                            />
                            <CardService
                                imageSrc="/images/services/services_4.png"
                                title={t('landing.ar')}
                                backgroundColor={theme.vars.palette.primary['100']}
                            />
                            <CardService
                                imageSrc="/images/services/services_5.png"
                                title={t('landing.digitalExperiences')}
                                backgroundColor={theme.vars.palette.background.commonWhite}
                            />
                            <CardService
                                imageSrc="/images/services/services_6.png"
                                title={t('landing.metaTikTokFilters')}
                                backgroundColor={theme.vars.palette.primary['100']}
                            />
                            <CardService
                                imageSrc="/images/services/services_7.png"
                                title={t('landing.productPackaging')}
                                backgroundColor={theme.vars.palette.background.commonWhite}
                                last={true}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Services;