import { Box, Grid, Typography, useTheme } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { Parallax } from "react-scroll-parallax";

const Rules = () =>{

    const theme = useTheme();
    const { t } = useTranslation();

    return(
        <>
            <Box sx={{position: "relative", zIndex: "100"}}>
                <Box m={0} p={0} py={{xs: 12, lg: 0}} display="flex" justifyContent="center" alignItems="center" sx={{width: "100vw", height: {xs: "100%",lg: "100dvh"}, backgroundColor: theme.vars.palette.background.body}}>
                    <Box display={{xs: "none", sm: "block"}}>
                        <Grid container  columns={7} sx={{flexGrow: 1}}>
                            <Grid sm={3} md={3}>
                                <Box display="flex" justifyContent="center">
                                    <Parallax speed={10}>
                                        <Box display={{sm: "none", md: "flex"}} justifyContent="center">
                                            <img alt="" src="/images/big_eye.png" />
                                        </Box>
                                        <Box display={{sm: "flex", md: "none"}} justifyContent="center">
                                            <img alt="" src="/images/eye.png" />
                                        </Box>
                                        <Box display={{sm: "none", md: "flex"}}>
                                            <Box>
                                                <img alt="" src="/images/big_eye.png" />
                                            </Box>
                                            <Box>
                                                <img alt="" src="/images/big_eye.png" />
                                            </Box>
                                        </Box>
                                        <Box display={{sm: "flex", md: "none"}}>
                                            <Box>
                                                <img alt="" src="/images/eye.png" />
                                            </Box>
                                            <Box>
                                                <img alt="" src="/images/eye.png" />
                                            </Box>
                                        </Box>
                                    </Parallax>
                                </Box>
                            </Grid>
                            <Grid sm={4} md={3} pr={{sm: 4, md:0}}>
                                <Box>
                                    <Typography mr={1} fontFamily="Raleway" sx={{color: "white", fontWeight: 700, fontSize: {xs: "16px", md: "24px" }, textAlign: "end"}}>
                                        {t('landing.rulesTitle1')}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography mr={1} fontFamily="Raleway" sx={{color: "white", fontWeight: 700, fontSize: {xs: "24px", md: "48px" }, textAlign: "end"}}>
                                        {t('landing.rulesTitle2')}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography mr={1} fontFamily="Raleway" sx={{color: "white", fontWeight: 500, fontSize: {xs: "14px", md: "20px" }, textAlign: "end", }}>
                                        {t('landing.rulesDescription')}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box  display={{xs: "block", sm: "none"}}>
                        <Grid mx={4} container  columns={7} sx={{flexGrow: 1}}>
                            <Grid xs={2} sm={2}>
                                <Box display="flex" justifyContent="end">
                                    <Parallax speed={5}>
                                        <Box display="flex" justifyContent="center">
                                            <img alt="" src="/images/eye.png" />
                                        </Box>
                                        <Box sx={{display: "flex"}}>
                                            <Box>
                                                <img alt="" src="/images/eye.png" />
                                            </Box>
                                            <Box>
                                                <img alt="" src="/images/eye.png" />
                                            </Box>
                                        </Box>
                                    </Parallax>
                                </Box>
                            </Grid>
                            <Grid xs={5} sm={3}>
                                <Box>
                                    <Typography mr={1} fontFamily="Raleway" sx={{color: "white", fontWeight: 700, fontSize: "16px", textAlign: "end"}}>
                                        {t('landing.rulesTitle1')}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography mr={1} fontFamily="Raleway" sx={{color: "white", fontWeight: 700, fontSize: "24px", textAlign: "end"}}>
                                        {t('landing.rulesTitle2')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid xs={7} sm={3}>
                                <Box mt={2}>
                                    <Typography mr={1} fontFamily="Raleway" sx={{color: "white", fontWeight: 500, fontSize: "14px", textAlign: "end", }}>
                                        {t('landing.rulesDescription')}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Rules;