import theme from "./components/theme"

import { BrowserRouter, Routes, Route} from 'react-router-dom';
import { CssVarsProvider } from "@mui/joy";

import { StoreContextProvider } from "./store";
import Landing from "./pages/landing";
import Gaming from "./pages/gaming";

const App = () => {

  return (
      <CssVarsProvider theme={theme} defaultMode="system">
        <StoreContextProvider>
          <BrowserRouter>
            <Routes>            
              <Route path="/" element={<Landing />} />
              <Route path="/gaming" element={<Gaming />} />
            </Routes>
          </BrowserRouter>   
        </StoreContextProvider>
      </CssVarsProvider>
  );
}

export default App;

