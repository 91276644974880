import { Box, Typography, useTheme } from "@mui/joy";

const CardService = ({ imageSrc, title, description, backgroundColor, readMoreText, last = false }) => {
  const theme = useTheme();

  return (
    <Box mb={0} mr={{ xs: last ? 10 : 0, xl: 0 }} sx={{
      minWidth: { xs: "300px", lg: "250px", xl: "280px" },
      height: "200px",
      py: 8,
      backgroundColor: backgroundColor,
      position: "relative"
    }}>
      <Box display="flex" justifyContent="center">
        <img alt="" src={imageSrc} />
      </Box>
      <Box display="flex" justifyContent="center">
        <Box mt={4}>
          <Typography fontFamily="Raleway" sx={{ color: theme.vars.palette.text.main, fontWeight: 800, fontSize: "20px", textAlign: "center", width: { xs: "100%", md: "180px" } }}>
            {title}
          </Typography>
        </Box>
      </Box>
      {/* <Box display="flex" justifyContent="center" sx={{ position: "absolute", bottom: "10px", right: { xs: "62px", lg: "10px" } }}>
        <Box display="flex" justifyContent="center" sx={{ width: "150px", px: "12px", py: "8px", borderRadius: "8px", backgroundColor: theme.vars.palette.primary['100'], cursor: "pointer" }}>
          <Typography fontFamily="Raleway" sx={{ color: theme.vars.palette.secondary['200'], fontWeight: 700, fontSize: "14px", textAlign: "center", width: { xs: "100%", md: "600px" } }}>
            {t('landing.readMore')}
          </Typography>
          <Box>
            <Plus />
          </Box>
        </Box>
      </Box> */}
    </Box>
  );
};

export default CardService;