import { Box, Typography, useTheme } from "@mui/joy";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Business = () =>{

    const theme = useTheme();
    const { t } = useTranslation();

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const calculateParallaxOffset = (factor) => {
        return scrollPosition * factor;
    };

    return(
        <>
            <Box sx={{position: "relative",}}>
                <Box m={0} p={0} display="flex" justifyContent="center" alignItems="center" sx={{width: "100vw", height: "100dvh", backgroundColor: "white"}}>
                    <Box px={{xs: 4, md: 0}}>
                        <Box>
                            <Typography fontFamily="Raleway" sx={{color: theme.vars.palette.text.main, fontWeight: 500, fontSize: {xs: "16px", md: "24px" }, textAlign: "center"}}>
                                {t('landing.welcomeMessage')}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography fontFamily="Raleway" sx={{color: theme.vars.palette.text.main, fontWeight: 800, fontSize: {xs: "24px", md: "48px" }, textAlign: "center"}}>
                                {t('landing.businessTitle')}
                            </Typography>
                        </Box>
                        <Box mx={2}>
                            <Typography fontFamily="Raleway" sx={{color: theme.vars.palette.text.secondary, fontWeight: 500, fontSize: {xs: "14px", md: "20px" }, textAlign: "center", width: {xs: "100%", md: "600px"}}}>
                                {t('landing.businessDescription')}
                            </Typography>
                        </Box>
                        <Box display={{xs: "none", md: "block"}}>
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "0%",
                                    left: {md: "-10%", lg:"15%"},
                                    transform: `translateY(${calculateParallaxOffset(0.1)}px)`
                                }}
                            >
                                <img alt="" src="/images/fish.png" />
                            </Box> 
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "30%",
                                    left: {md: "-20%", lg:"-5%"},
                                    transform: `translateY(${calculateParallaxOffset(0.08)}px)`
                                }}
                            >
                                <img alt="" src="/images/fish.png" />
                            </Box> 
                            <Box
                                sx={{
                                    position: "absolute",
                                    bottom: "10%",
                                    right: {md: "60%", lg:"38%"},
                                    transform: `translateY(${calculateParallaxOffset(0.15)}px)`
                                }}
                            >
                                <img alt="" src="/images/fish.png" />
                            </Box> 
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: {md: "-10%", lg: "-8%", xl: "5%"},
                                    right: {md: "-20%", lg:"10%", xl: "10%"},
                                    transform: `translateY(${calculateParallaxOffset(0.2)}px)`
                                }}
                            >
                                <img alt="" src="/images/fish.png" />
                            </Box> 
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "60%",
                                    right: {xs: "38%",md: "0%", lg:"-10%"},
                                    transform: `translateY(${calculateParallaxOffset(0.09)}px)`
                                }}
                            >
                                <img alt="" src="/images/fish.png" />
                            </Box> 
                        </Box>
                        <Box display={{xs: "block", md: "none"}}>
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: {xs: "0%", sm:"0%"},
                                    left: {xs: "-50%", sm:"0%"},
                                    transform: `translateY(${calculateParallaxOffset(0.08)}px)`
                                }}
                            >
                                <img alt="" src="/images/fish.png" />
                            </Box> 
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: {xs: "-8%", sm:"0%"},
                                    right: {xs: "-50%", sm:"0%"},
                                    transform: `translateY(${calculateParallaxOffset(0.2)}px)`
                                }}
                            >
                                <img alt="" src="/images/fish.png" />
                            </Box> 
                            <Box
                                sx={{
                                    position: "absolute",
                                    bottom: {xs: "10%", sm:"20%"},
                                    right: {xs: "10%", sm:"30%"},
                                    transform: `translateY(${calculateParallaxOffset(0.15)}px)`
                                }}
                            >
                                <img alt="" src="/images/fish.png" />
                            </Box> 
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Business;