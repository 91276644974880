import { Box } from "@mui/joy";

const Logo = () =>{

    return(
        <>
            <Box display={{xs: "none", xl: "block"}}>
                <svg width="547" height="128" viewBox="0 0 547 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_84_2348)">
                    <path d="M6.4967 22.0324C10.685 16.4404 15.8686 11.6313 23.4298 8.2482C30.9356 4.90701 37.9023 4.48761 43.6527 6.61255C49.3615 8.76546 52.9417 13.253 52.8864 19.6558C52.8725 33.4959 47.2743 49.5728 46.7352 68.1661C46.514 76.7218 46.0717 83.8096 43.5835 88.7724C41.1369 93.7912 36.3542 96.1678 28.6963 96.4474C21.066 96.755 14.6522 95.0354 10.3809 91.4426C6.08201 87.8358 3.85652 82.7192 3.1792 76.1486C1.76926 61.8611 0.649608 50.2718 0.0275774 38.8083C-0.290349 33.5518 2.37747 27.6523 6.51052 22.0324H6.4967ZM25.8488 81.461C30.8527 80.9157 32.6773 77.253 32.6496 70.5147C32.8984 52.187 37.3494 35.5649 36.4924 21.8087C36.1883 16.8179 32.6082 15.2801 26.844 17.5029C21.0246 19.8375 18.495 23.5562 18.8405 28.1975C19.8911 41.073 17.5136 56.2692 17.8315 73.087C18.0112 79.2242 20.9278 82.0341 25.8626 81.461H25.8488Z" fill="#F9FBFC"/>
                    <path d="M73.3857 96.9367C69.1421 96.4754 64.857 96.084 60.5166 95.7624C44.3023 53.8646 74.7542 25.3317 57.6414 0.349571C64.1658 -0.181665 70.6073 -0.0977858 76.8691 0.391511C94.1201 19.558 90.1253 34.3906 89.9179 53.1936C95.0462 34.7262 104.708 22.9551 91.9499 2.2788C96.2212 2.99177 100.41 3.80261 104.543 4.6414C115.49 24.3251 111.178 32.8808 105.662 47.0424C100.257 60.7288 96.0415 76.8756 105.109 101.089C100.354 100.404 95.613 99.7187 90.8579 99.0757C72.0311 64.9787 80.8501 43.0302 77.0212 23.011C74.3672 41.9538 60.9036 65.2303 73.4272 96.9227L73.3857 96.9367Z" fill="#F9FBFC"/>
                    <path d="M127.917 9.54834C139.432 31.6086 136.902 39.5073 130.806 52.8721C125.318 65.6777 119.402 80.2867 129.244 104.36C124.475 103.801 119.762 103.172 115.103 102.529C105.814 78.0918 110.625 62.63 116.154 49.3771C121.973 35.6069 125.622 27.5545 114.412 6.75236C118.96 7.73096 123.452 8.68159 127.917 9.56232V9.54834Z" fill="#F9FBFC"/>
                    <path d="M166.994 102.71C166.041 101.298 164.727 98.6702 164.064 94.8677C163.138 89.4994 163.58 84.3828 164.562 79.8253C165.446 75.841 165.654 73.115 164.686 71.2277C163.732 69.3544 161.59 68.0403 158.189 67.2294C156.489 66.824 154.789 66.4186 153.102 65.9992C149.218 75.8131 144.919 91.0652 154.595 106.583C149.578 106.317 144.629 105.94 139.763 105.492C129.327 82.2159 136.169 68.1102 141.477 55.5143C147.642 42.3452 149.287 34.125 137.648 11.3378C144.353 12.4701 151.043 13.3649 157.761 13.966C164.645 14.5392 170.865 17.461 175.966 22.4658C180.997 27.4426 185.517 34.5025 186.996 42.2753C187.48 44.8895 187.729 47.364 187.77 49.7266C187.77 58.9673 182.435 63.3849 175.274 64.4894C178.274 66.3627 180.029 68.7812 180.555 71.8148C181.094 74.8624 180.582 78.5112 179.629 83.0966C178.772 87.3605 178.426 91.9878 179.477 96.6152C180.057 99.1735 181.094 101.215 182.213 102.668C183.333 104.136 184.978 105.492 187.217 106.639C181.868 106.89 176.546 107.016 171.28 107.016C169.331 105.604 167.962 104.122 167.008 102.696L166.994 102.71ZM157.65 55.5422C159.544 56.0036 161.424 56.4509 163.304 56.8843C166.193 57.5553 168.584 57.4575 170.395 56.5488C172.192 55.6401 173.505 53.7388 173.878 50.6493C174.196 47.9791 174.196 45.1691 173.795 42.1355C173.353 38.7943 172.054 36.0542 170.478 34.2508C168.888 32.4334 166.856 31.329 164.506 30.9935C162.364 30.686 160.207 30.3365 158.037 29.973C161.659 40.5558 160.691 48.2447 157.65 55.5422Z" fill="#F9FBFC"/>
                    <path d="M200.487 14.637C209.03 38.5706 212.375 54.0743 209.058 68.5995C207.178 82.6353 199.893 95.5527 211.725 104.71C206.417 105.283 201.109 105.772 195.829 106.136C183.043 93.5396 191.696 80.4544 194.626 67.0617C199.133 53.0678 196.52 39.4514 186.72 15.0005C191.281 14.9446 195.871 14.8048 200.474 14.6231L200.487 14.637Z" fill="#F9FBFC"/>
                    <path d="M268.054 83.5859C269.035 89.6672 266.893 93.5815 263.022 96.0979C259.083 98.6283 253.927 99.8166 246.31 100.809C238.763 101.746 231.492 102.095 225.369 100.544C219.273 99.0057 215.54 95.2871 215.803 89.5274C216.55 77.281 221.429 62.2945 217.572 43.5475C215.941 34.838 214.905 27.149 216.757 21.2635C218.54 15.35 223.115 11.9669 229.889 12.0787C236.648 12.1766 242.44 15.0984 247.126 18.7192C251.798 22.4378 255.115 27.163 257.355 35.0477C258.239 38.1932 259.124 41.4225 259.967 44.6659C255.378 46.3994 250.899 47.8673 246.504 49.0695C245.882 45.3649 245.135 41.6182 244.292 37.8716C242.716 31.0215 239.371 28.0438 234.754 28.7707C230.082 29.4837 228.99 33.6357 230.511 40.8074C234.823 60.4072 232.225 77.8821 232.626 89.6951C232.902 93.959 236.634 95.3011 242.633 94.63C248.688 93.8751 251.287 91.89 250.706 87.6121C250.291 84.4387 250.001 80.8179 249.683 76.8476C254.991 75.7991 260.451 74.4431 266.215 72.7235C266.934 76.6379 267.556 80.3006 268.081 83.5999L268.054 83.5859Z" fill="#F9FBFC"/>
                    <path d="M266.893 119.584C261.695 120.465 256.525 121.555 251.632 122.883C250.72 118.801 249.476 114.985 248.121 111.308C253.07 109.658 258.184 108.232 263.299 106.988C264.363 111.084 265.676 115.222 266.879 119.584H266.893Z" fill="#F9FBFC"/>
                    <path d="M266.519 38.305C268.579 33.468 272.574 29.3998 280.218 26.6458C287.779 23.9197 295.769 24.0036 302.348 26.9394C308.928 29.8751 313.158 35.3553 314.015 42.485C314.139 43.4077 314.236 44.3304 314.319 45.267C309.536 45.5885 304.712 46.1757 299.846 47.0844C299.653 45.8961 299.432 44.7078 299.169 43.5335C298.519 40.6117 297.137 38.6405 295.271 37.6619C293.405 36.6833 291.083 36.5435 288.429 37.3124C285.775 38.0813 284.047 39.3535 283.19 41.0311C282.319 42.7227 282.416 44.9035 283.204 47.6576C284.171 51.0407 285.899 53.7109 288.512 55.7799C291.124 57.8489 294.718 60.1556 299.058 62.9795C302.666 65.426 305.403 67.8306 307.477 70.0114C309.55 72.1923 311.278 74.9743 312.785 78.3994C314.291 81.8245 315.135 85.8926 315.591 90.6458C316.351 98.7262 316.24 105.367 313.877 110.078C311.527 114.831 306.896 117.208 299.432 117.445C291.981 117.725 285.291 116.033 280.052 112.678C274.827 109.295 271.288 104.472 270.321 97.6078C270.086 95.8743 269.892 94.1268 269.726 92.3933C275.007 90.9953 280.121 89.807 285.07 88.8004C285.139 90.9673 285.263 93.1482 285.484 95.329C286.093 101.243 289.631 103.815 294.856 103.256C300.15 102.696 301.809 99.2993 301.215 92.9944C300.842 88.9402 299.736 85.5571 297.593 82.9289C295.437 80.3006 292.299 77.5186 287.848 74.9324C284.144 72.6956 280.923 70.9201 278.338 69.4243C275.753 67.9284 273.362 65.9992 271.081 63.6646C268.786 61.3299 267.197 58.4081 266.174 54.7733C264.418 48.6362 264.487 43.198 266.519 38.319V38.305Z" fill="#F9FBFC"/>
                    <path d="M357.875 22.4378C360.819 28.2954 362.271 33.5658 363.128 38.2071C358.234 37.2146 353.244 36.2779 348.13 35.495C350.908 47.7694 346.609 59.9319 346.582 72.3181C345.877 84.9839 349.429 97.8874 352.18 118.074C347.95 117.613 343.637 117.208 339.228 116.886C335.44 98.2928 332.579 84.956 332.98 71.8148C332.897 58.8834 336.076 46.1617 333.118 33.7755C327.769 33.3981 322.309 33.3002 316.766 33.5798C315.466 29.302 313.365 24.9542 309.647 20.7043C326.608 18.5654 343.001 20.0053 357.875 22.4378Z" fill="#F9FBFC"/>
                    <path d="M380.102 93.3718C380.71 100.138 383.268 104.584 387.622 105.115C391.99 105.772 393.994 101.788 393.759 95.0215C392.087 67.5789 405.066 62.7698 391.644 28.547C395.916 29.274 400.187 29.945 404.486 30.5182C417.687 64.6711 406.919 69.7039 406.241 95.8183C406.131 103.997 404.541 111.979 401.721 117.543C398.915 123.149 394.948 125.875 388.811 124.98C382.687 124.142 377.766 119.962 374.117 113.587C370.495 107.24 367.758 99.4251 366.763 91.1351C361.925 63.9581 377.227 55.4444 363.501 23.3745C368.256 24.2133 372.9 25.094 377.503 25.9608C390.995 59.5265 376.398 65.5798 380.102 93.3579V93.3718Z" fill="#F9FBFC"/>
                    <path d="M436.168 33.1604C443.425 33.3561 449.286 36.5156 454.069 41.758C458.81 46.9726 462.763 53.9066 463.758 61.5815C465.984 77.7144 460.137 88.5767 457.58 103.969C456.322 111.378 454.608 117.627 452.05 121.779C449.466 125.945 445.526 128.14 438.393 127.972C430.901 127.748 423.603 127.259 416.539 126.574C413.319 100.627 415.931 88.423 418.682 75.9669C422.718 62.9795 420.824 55.6261 413.83 31.6226C421.101 32.3775 428.524 32.8808 436.182 33.1464L436.168 33.1604ZM428.081 110.022C430.57 110.427 433.099 110.833 435.629 111.21C437.84 111.546 439.568 110.945 440.868 109.491C442.167 108.051 442.969 105.548 443.425 102.249C445.485 85.641 452.244 75.9808 449.507 60.0857C448.996 56.9682 447.738 54.4798 446.093 52.886C444.448 51.2784 442.43 50.3976 440.135 50.2578C437.495 50.0901 434.868 49.9083 432.256 49.6847C440.259 73.9538 427.722 81.7825 428.081 110.008V110.022Z" fill="#F9FBFC"/>
                    <path d="M484.59 32.6851C487.838 55.2906 488.736 70.8083 485.778 85.5012C483.567 99.9424 477.526 113.573 484.133 126.434C479.212 126.868 474.278 127.231 469.343 127.511C462.196 111.224 469.163 97.9992 471.485 84.257C474.651 70.333 473.158 55.9057 468.431 33.1604C473.711 33.0346 479.102 32.8528 484.576 32.6711L484.59 32.6851Z" fill="#F9FBFC"/>
                    <path d="M502.034 39.2137C506.112 33.468 512.802 30.2386 521.262 30.4203C529.721 30.5881 536.605 33.44 541.194 37.2845C545.756 41.1988 547.581 46.3155 546.834 54.4518C545.079 72.1643 543.102 91.0092 541.333 104.919C540.476 111.42 537.241 115.81 532.804 118.745C528.311 121.695 523.197 123.093 516.175 123.834C509.208 124.519 502.808 124.239 497.79 121.751C492.786 119.305 490.34 114.523 491.294 107.995C493.491 93.8611 497.5 78.9306 497.099 60.7008C496.933 52.3268 497.915 44.9455 502.034 39.2277V39.2137ZM514.765 115.432C520.322 114.971 523.238 112.65 523.736 107.659C524.994 94.0009 527.828 75.4916 529.694 56.3531C530.288 49.419 527.316 46.2875 521.428 46.8327C515.511 47.364 512.885 51.3762 512.678 58.4361C511.862 77.7563 508.448 94.8397 506.831 108.764C506.305 113.838 509.236 115.852 514.765 115.432Z" fill="#F9FBFC"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_84_2348">
                    <rect width="547" height="128" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
            </Box>
            <Box display={{xs: "none", lg: "block", xl: "none"}}>
                <svg width="599" height="140" viewBox="0 0 599 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_80_1680)">
                    <path d="M7.11411 24.0979C11.7006 17.9817 17.377 12.7218 25.6569 9.02147C33.8763 5.36704 41.5053 4.90832 47.8023 7.23248C54.0538 9.58722 57.9743 14.4955 57.9138 21.4985C57.8986 36.6361 51.7682 54.2202 51.1778 74.5566C50.9356 83.9144 50.4512 91.6667 47.7266 97.0949C45.0473 102.584 39.81 105.184 31.4241 105.489C23.0685 105.826 16.0449 103.945 11.3676 100.015C6.66 96.0704 4.22295 90.4741 3.48124 83.2875C1.93727 67.6606 0.711177 54.9848 0.0300133 42.4465C-0.318137 36.6973 2.6033 30.2447 7.12925 24.0979H7.11411ZM28.3059 89.0979C33.7854 88.5016 35.7835 84.4955 35.7533 77.1254C36.0257 57.0796 40.8998 38.8991 39.9613 23.8533C39.6283 18.3946 35.7078 16.7126 29.3957 19.1438C23.0231 21.6973 20.253 25.7646 20.6314 30.841C21.7818 44.9236 19.1783 61.5444 19.5264 79.9389C19.7232 86.6514 22.9171 89.7248 28.321 89.0979H28.3059Z" fill="#F9FBFC"/>
                    <path d="M80.3619 106.025C75.7148 105.52 71.0224 105.092 66.2694 104.74C48.5137 58.9145 81.8604 27.7065 63.1209 0.382341C70.2655 -0.198699 77.3194 -0.106955 84.1764 0.428213C103.067 21.3915 98.6928 37.6148 98.4657 58.1805C104.082 37.9817 114.662 25.1071 100.691 2.49243C105.368 3.27225 109.955 4.1591 114.481 5.07653C126.469 26.6056 121.746 35.9634 115.707 51.4527C109.788 66.4221 105.171 84.0826 115.101 110.566C109.894 109.817 104.702 109.067 99.495 108.364C78.8785 71.0704 88.5359 47.0643 84.3429 25.1683C81.4366 45.8869 66.6932 71.3456 80.4073 106.009L80.3619 106.025Z" fill="#F9FBFC"/>
                    <path d="M140.077 10.4435C152.686 34.5719 149.916 43.2111 143.241 57.8288C137.231 71.8349 130.753 87.8135 141.53 114.144C136.308 113.532 131.146 112.844 126.045 112.141C115.873 85.4129 121.141 68.5016 127.196 54.0062C133.568 38.945 137.564 30.1377 125.288 7.38539C130.268 8.45573 135.188 9.49549 140.077 10.4588V10.4435Z" fill="#F9FBFC"/>
                    <path d="M182.869 112.34C181.825 110.795 180.387 107.921 179.66 103.762C178.646 97.89 179.131 92.2936 180.205 87.3089C181.174 82.9511 181.401 79.9695 180.342 77.9053C179.297 75.8563 176.951 74.419 173.227 73.5322C171.365 73.0888 169.503 72.6453 167.657 72.1866C163.403 82.9206 158.696 99.6025 169.292 116.575C163.797 116.284 158.378 115.872 153.05 115.382C141.621 89.9236 149.114 74.4955 154.927 60.7187C161.678 46.3151 163.479 37.3242 150.734 12.4007C158.075 13.6392 165.401 14.6178 172.758 15.2753C180.296 15.9022 187.108 19.0979 192.693 24.5719C198.203 30.0154 203.153 37.7371 204.773 46.2386C205.302 49.0979 205.575 51.8043 205.62 54.3884C205.62 64.4955 199.777 69.3273 191.936 70.5352C195.221 72.5842 197.144 75.2294 197.719 78.5475C198.309 81.8808 197.749 85.8716 196.705 90.8869C195.766 95.5505 195.388 100.612 196.538 105.673C197.174 108.471 198.309 110.703 199.535 112.294C200.761 113.899 202.563 115.382 205.015 116.636C199.157 116.911 193.329 117.049 187.562 117.049C185.428 115.505 183.929 113.884 182.885 112.324L182.869 112.34ZM172.637 60.7493C174.711 61.2539 176.769 61.7432 178.828 62.2172C181.991 62.9511 184.61 62.8441 186.593 61.8502C188.561 60.8563 189.999 58.7768 190.408 55.3976C190.756 52.4771 190.756 49.4037 190.317 46.0857C189.832 42.4313 188.41 39.4343 186.684 37.4618C184.943 35.4741 182.718 34.2661 180.145 33.8992C177.799 33.5628 175.437 33.1805 173.061 32.7829C177.027 44.3579 175.967 52.7677 172.637 60.7493Z" fill="#F9FBFC"/>
                    <path d="M219.546 16.0093C228.901 42.1866 232.564 59.1438 228.931 75.0307C226.873 90.3823 218.895 104.511 231.853 114.526C226.04 115.153 220.227 115.688 214.445 116.086C200.443 102.309 209.919 87.997 213.128 73.3487C218.063 58.0429 215.202 43.1499 204.47 16.4068C209.465 16.3456 214.491 16.1927 219.531 15.994L219.546 16.0093Z" fill="#F9FBFC"/>
                    <path d="M293.536 91.4221C294.611 98.0735 292.264 102.355 288.026 105.107C283.712 107.875 278.066 109.174 269.725 110.26C261.461 111.284 253.499 111.667 246.793 109.969C240.117 108.288 236.03 104.22 236.318 97.9206C237.135 84.5261 242.479 68.1346 238.256 47.63C236.469 38.104 235.334 29.6943 237.363 23.2569C239.315 16.7891 244.326 13.0888 251.743 13.2111C259.145 13.3181 265.487 16.5138 270.618 20.4741C275.735 24.5414 279.368 29.7095 281.82 38.3334C282.789 41.7738 283.757 45.3059 284.681 48.8533C279.655 50.7493 274.751 52.3548 269.937 53.6698C269.256 49.6178 268.439 45.5199 267.515 41.4221C265.79 33.9297 262.127 30.6728 257.071 31.468C251.955 32.2478 250.759 36.7891 252.424 44.6331C257.147 66.0704 254.301 85.1836 254.74 98.104C255.042 102.768 259.129 104.236 265.699 103.502C272.329 102.676 275.175 100.505 274.539 95.8258C274.085 92.3548 273.767 88.3946 273.419 84.0521C279.231 82.9053 285.21 81.4221 291.523 79.5414C292.31 83.8227 292.991 87.8288 293.566 91.4374L293.536 91.4221Z" fill="#F9FBFC"/>
                    <path d="M292.264 130.795C286.573 131.758 280.912 132.951 275.553 134.404C274.554 129.939 273.192 125.765 271.708 121.743C277.127 119.939 282.728 118.379 288.329 117.018C289.494 121.499 290.932 126.025 292.249 130.795H292.264Z" fill="#F9FBFC"/>
                    <path d="M291.856 41.8961C294.111 36.6056 298.486 32.156 306.856 29.1438C315.136 26.1622 323.885 26.2539 331.091 29.4649C338.296 32.6759 342.928 38.6698 343.866 46.468C344.002 47.4771 344.108 48.4863 344.199 49.5108C338.962 49.8625 333.679 50.5047 328.351 51.4986C328.139 50.1989 327.897 48.8992 327.609 47.6148C326.898 44.419 325.384 42.2631 323.34 41.1927C321.297 40.1224 318.754 39.9695 315.848 40.8105C312.941 41.6515 311.049 43.0429 310.111 44.8778C309.157 46.7279 309.263 49.1132 310.126 52.1255C311.185 55.8258 313.078 58.7463 315.938 61.0093C318.799 63.2723 322.735 65.7952 327.488 68.8839C331.439 71.5597 334.436 74.1897 336.706 76.575C338.977 78.9603 340.869 82.0031 342.519 85.7493C344.169 89.4955 345.092 93.945 345.592 99.1438C346.424 107.982 346.303 115.245 343.715 120.398C341.142 125.596 336.071 128.196 327.897 128.456C319.738 128.762 312.412 126.911 306.675 123.242C300.953 119.541 297.078 114.266 296.018 106.758C295.761 104.862 295.549 102.951 295.367 101.055C301.15 99.5261 306.75 98.2264 312.169 97.1255C312.245 99.4955 312.381 101.881 312.623 104.266C313.29 110.734 317.165 113.547 322.886 112.936C328.684 112.324 330.5 108.609 329.849 101.713C329.441 97.2784 328.23 93.5781 325.883 90.7034C323.522 87.8288 320.086 84.786 315.212 81.9573C311.155 79.5108 307.628 77.5689 304.798 75.9328C301.967 74.2967 299.348 72.1866 296.851 69.6331C294.338 67.0796 292.597 63.8839 291.477 59.9083C289.555 53.1958 289.63 47.2478 291.856 41.9114V41.8961Z" fill="#F9FBFC"/>
                    <path d="M391.896 24.5414C395.12 30.9481 396.709 36.7126 397.648 41.7891C392.289 40.7034 386.825 39.679 381.224 38.8227C384.267 52.2478 379.559 65.5505 379.529 79.0979C378.757 92.9511 382.647 107.064 385.659 129.144C381.027 128.639 376.305 128.196 371.476 127.844C367.328 107.508 364.195 92.9206 364.634 78.5475C364.543 64.4037 368.025 50.4894 364.785 36.942C358.927 36.5291 352.948 36.4221 346.878 36.7279C345.456 32.049 343.155 27.2937 339.083 22.6453C357.656 20.3059 375.608 21.8808 391.896 24.5414Z" fill="#F9FBFC"/>
                    <path d="M416.236 102.125C416.902 109.526 419.702 114.388 424.471 114.969C429.254 115.688 431.449 111.33 431.191 103.93C429.36 73.9144 443.573 68.6545 428.875 31.2233C433.553 32.0184 438.23 32.7524 442.938 33.3793C457.393 70.734 445.602 76.2386 444.86 104.801C444.739 113.746 442.998 122.477 439.91 128.563C436.837 134.694 432.493 137.676 425.772 136.697C419.067 135.78 413.678 131.208 409.682 124.236C405.716 117.294 402.719 108.746 401.629 99.679C396.331 69.9542 413.088 60.6423 398.057 25.5658C403.264 26.4833 408.35 27.4466 413.39 28.3946C428.164 65.1071 412.179 71.7279 416.236 102.11V102.125Z" fill="#F9FBFC"/>
                    <path d="M477.632 36.2692C485.578 36.4833 491.997 39.9389 497.234 45.6729C502.426 51.3762 506.755 58.9603 507.845 67.3548C510.282 85.0001 503.879 96.8808 501.079 113.716C499.701 121.82 497.824 128.655 495.024 133.196C492.193 137.752 487.879 140.153 480.069 139.969C471.864 139.725 463.872 139.19 456.137 138.44C452.61 110.061 455.471 96.7126 458.483 83.0888C462.903 68.8839 460.83 60.8411 453.17 34.5872C461.132 35.4129 469.261 35.9634 477.647 36.2539L477.632 36.2692ZM468.776 120.336C471.501 120.78 474.271 121.223 477.041 121.636C479.463 122.003 481.355 121.346 482.778 119.755C484.201 118.18 485.079 115.443 485.578 111.835C487.834 93.6698 495.236 83.104 492.239 65.7187C491.679 62.3089 490.301 59.5872 488.5 57.8441C486.699 56.0857 484.489 55.1224 481.976 54.9695C479.085 54.786 476.209 54.5872 473.348 54.3426C482.112 80.8869 468.383 89.4496 468.776 120.321V120.336Z" fill="#F9FBFC"/>
                    <path d="M530.656 35.7493C534.214 60.4741 535.197 77.4466 531.958 93.5169C529.536 109.312 522.921 124.22 530.157 138.288C524.768 138.762 519.364 139.159 513.96 139.465C506.134 121.651 513.763 107.187 516.306 92.156C519.773 76.9267 518.138 61.1469 512.961 36.2692C518.744 36.1316 524.647 35.9328 530.641 35.734L530.656 35.7493Z" fill="#F9FBFC"/>
                    <path d="M549.759 42.89C554.225 36.6056 561.551 33.0735 570.815 33.2722C580.079 33.4557 587.617 36.575 592.642 40.7799C597.637 45.0612 599.635 50.6576 598.818 59.5566C596.896 78.9297 594.731 99.5414 592.794 114.755C591.855 121.866 588.313 126.667 583.454 129.878C578.535 133.104 572.934 134.633 565.244 135.444C557.615 136.193 550.607 135.887 545.112 133.165C539.633 130.489 536.953 125.26 537.998 118.119C540.405 102.661 544.794 86.3303 544.355 66.3915C544.174 57.2325 545.248 49.1591 549.759 42.9053V42.89ZM563.7 126.254C569.785 125.749 572.979 123.211 573.524 117.752C574.902 102.814 578.005 82.5689 580.048 61.6362C580.699 54.0521 577.445 50.627 570.996 51.2233C564.518 51.8044 561.642 56.1927 561.415 63.9144C560.522 85.0459 556.783 103.731 555.012 118.96C554.437 124.511 557.646 126.713 563.7 126.254Z" fill="#F9FBFC"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_80_1680">
                    <rect width="599" height="140" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
            </Box>
            <Box display={{xs: "none", md: "block", lg: "none"}}>
                <svg width="411" height="96" viewBox="0 0 411 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_63_746)">
                    <path d="M4.88143 16.5243C8.02843 12.3303 11.9232 8.7235 17.6044 6.18615C23.2441 3.68025 28.4787 3.3657 32.7994 4.95941C37.0888 6.57409 39.7788 9.93975 39.7373 14.7419C39.7269 25.1219 35.5205 37.1796 35.1155 51.1245C34.9493 57.5413 34.6169 62.8572 32.7474 66.5793C30.9091 70.3434 27.3155 72.1259 21.5616 72.3356C15.8284 72.5662 11.0092 71.2766 7.79993 68.582C4.56984 65.8768 2.89768 62.0394 2.38876 57.1114C1.32937 46.3959 0.488094 37.7038 0.0207182 29.1062C-0.218163 25.1639 1.78636 20.7392 4.89181 16.5243H4.88143ZM19.422 61.0957C23.1818 60.6868 24.5528 57.9398 24.532 52.886C24.7189 39.1403 28.0633 26.6737 27.4193 16.3565C27.1908 12.6134 24.5008 11.4601 20.1698 13.1272C15.7973 14.8782 13.8966 17.6671 14.1562 21.1481C14.9456 30.8048 13.1592 42.2019 13.3981 54.8152C13.5331 59.4181 15.7245 61.5256 19.4324 61.0957H19.422Z" fill="#F9FBFC"/>
                    <path d="M55.1399 72.7025C51.9514 72.3565 48.7317 72.063 45.4704 71.8218C33.2875 40.3985 56.1681 18.9987 43.3101 0.262176C48.2124 -0.136252 53.0523 -0.0733422 57.7572 0.29363C70.7191 14.6685 67.7175 25.793 67.5617 39.8952C71.415 26.0446 78.6749 17.2163 69.0885 1.7091C72.2978 2.24383 75.4448 2.85195 78.5503 3.48105C86.7761 18.2438 83.5356 24.6606 79.3915 35.2818C75.3306 45.5466 72.1628 57.6567 78.9761 75.8166C75.4033 75.3028 71.8408 74.789 68.268 74.3067C54.1221 48.734 60.7484 32.2727 57.8715 17.2582C55.8773 31.4653 45.7612 48.9227 55.1711 72.6921L55.1399 72.7025Z" fill="#F9FBFC"/>
                    <path d="M96.1132 7.16125C104.765 23.7065 102.864 29.6305 98.2839 39.654C94.1606 49.2582 89.7154 60.215 97.1103 78.27C93.5271 77.8506 89.9854 77.3788 86.4853 76.8965C79.5058 58.5689 83.1202 46.9725 87.2746 37.0328C91.6472 26.7052 94.3891 20.6658 85.966 5.06427C89.383 5.79821 92.7585 6.51119 96.1132 7.17174V7.16125Z" fill="#F9FBFC"/>
                    <path d="M125.475 77.0328C124.758 75.9738 123.771 74.0027 123.273 71.1508C122.577 67.1246 122.909 63.2871 123.647 59.869C124.312 56.8808 124.467 54.8362 123.74 53.4208C123.024 52.0158 121.414 51.0302 118.859 50.4221C117.581 50.118 116.304 49.8139 115.037 49.4994C112.118 56.8598 108.888 68.2989 116.158 79.9371C112.388 79.7379 108.67 79.4548 105.014 79.1193C97.1726 61.6619 102.314 51.0826 106.302 41.6357C110.934 31.7589 112.17 25.5938 103.425 8.50332C108.462 9.3526 113.489 10.0236 118.537 10.4745C123.709 10.9044 128.383 13.0957 132.215 16.8493C135.996 20.582 139.392 25.8769 140.504 31.7065C140.867 33.6672 141.054 35.523 141.085 37.2949C141.085 44.2255 137.076 47.5387 131.696 48.367C133.95 49.772 135.269 51.5859 135.664 53.8611C136.069 56.1468 135.684 58.8834 134.968 62.3225C134.324 65.5204 134.064 68.9909 134.853 72.4614C135.29 74.3801 136.069 75.9109 136.91 77.0014C137.751 78.1023 138.987 79.1193 140.67 79.9791C136.65 80.1678 132.652 80.2622 128.695 80.2622C127.23 79.2032 126.202 78.0918 125.485 77.0223L125.475 77.0328ZM118.454 41.6567C119.877 42.0027 121.289 42.3382 122.702 42.6632C124.872 43.1665 126.669 43.0931 128.03 42.4116C129.38 41.7301 130.367 40.3041 130.647 37.9869C130.886 35.9843 130.886 33.8769 130.585 31.6016C130.252 29.0957 129.276 27.0407 128.092 25.6881C126.898 24.3251 125.371 23.4968 123.605 23.2451C121.995 23.0145 120.375 22.7523 118.745 22.4797C121.466 30.4168 120.739 36.1835 118.454 41.6567Z" fill="#F9FBFC"/>
                    <path d="M150.64 10.9778C157.059 28.928 159.572 40.5558 157.08 51.4496C155.667 61.9765 150.194 71.6645 159.084 78.5322C155.096 78.9621 151.108 79.329 147.14 79.6016C137.533 70.1547 144.035 60.3408 146.237 50.2963C149.623 39.8008 147.66 29.5885 140.296 11.2504C143.723 11.2084 147.171 11.1036 150.63 10.9673L150.64 10.9778Z" fill="#F9FBFC"/>
                    <path d="M201.408 62.6894C202.145 67.2504 200.535 70.1861 197.627 72.0734C194.667 73.9712 190.793 74.8624 185.07 75.6069C179.4 76.3094 173.937 76.5715 169.335 75.4076C164.755 74.2543 161.951 71.4653 162.148 67.1455C162.709 57.9607 166.375 46.7209 163.478 32.6606C162.252 26.1285 161.473 20.3618 162.865 15.9476C164.205 11.5125 167.643 8.97514 172.732 9.05902C177.811 9.13242 182.162 11.3238 185.683 14.0394C189.194 16.8284 191.686 20.3723 193.369 26.2858C194.034 28.6449 194.698 31.0669 195.332 33.4994C191.884 34.7995 188.519 35.9004 185.216 36.8021C184.748 34.0236 184.188 31.2137 183.554 28.4037C182.37 23.2661 179.857 21.0328 176.388 21.578C172.877 22.1128 172.057 25.2268 173.199 30.6056C176.44 45.3054 174.487 58.4116 174.788 67.2713C174.996 70.4693 177.8 71.4758 182.308 70.9725C186.857 70.4063 188.809 68.9175 188.373 65.7091C188.062 63.329 187.844 60.6134 187.605 57.6357C191.593 56.8493 195.695 55.8323 200.026 54.5426C200.567 57.4784 201.034 60.2255 201.429 62.6999L201.408 62.6894Z" fill="#F9FBFC"/>
                    <path d="M200.535 89.6881C196.63 90.3487 192.746 91.1665 189.069 92.1626C188.384 89.101 187.449 86.2386 186.431 83.4811C190.149 82.2438 193.992 81.1744 197.835 80.2412C198.635 83.3133 199.621 86.4168 200.525 89.6881H200.535Z" fill="#F9FBFC"/>
                    <path d="M200.255 28.7288C201.802 25.101 204.804 22.0499 210.548 19.9843C216.229 17.9398 222.232 18.0027 227.176 20.2045C232.12 22.4063 235.298 26.5164 235.942 31.8637C236.035 32.5558 236.108 33.2478 236.17 33.9503C232.577 34.1914 228.952 34.6318 225.296 35.3133C225.151 34.4221 224.984 33.5309 224.787 32.6501C224.299 30.4588 223.26 28.9804 221.858 28.2464C220.456 27.5125 218.711 27.4077 216.717 27.9843C214.723 28.561 213.425 29.5151 212.781 30.7733C212.126 32.042 212.199 33.6776 212.791 35.7432C213.518 38.2805 214.816 40.2831 216.779 41.8349C218.742 43.3867 221.443 45.1167 224.704 47.2347C227.415 49.0695 229.471 50.8729 231.029 52.5086C232.587 54.1442 233.885 56.2307 235.017 58.7995C236.149 61.3683 236.783 64.4194 237.126 67.9843C237.697 74.0446 237.614 79.025 235.838 82.5584C234.072 86.1233 230.593 87.9057 224.984 88.0839C219.386 88.2936 214.359 87.025 210.423 84.5086C206.497 81.9712 203.838 78.3539 203.111 73.2058C202.935 71.9057 202.789 70.5951 202.665 69.2949C206.632 68.2464 210.475 67.3552 214.193 66.6003C214.245 68.2255 214.339 69.8611 214.505 71.4968C214.962 75.9319 217.621 77.8611 221.547 77.4417C225.524 77.0223 226.771 74.4745 226.324 69.7458C226.044 66.7052 225.213 64.1678 223.603 62.1966C221.983 60.2255 219.625 58.139 216.281 56.1993C213.497 54.5217 211.077 53.1901 209.135 52.0682C207.193 50.9463 205.396 49.4994 203.682 47.7484C201.958 45.9974 200.764 43.8061 199.995 41.08C198.676 36.4771 198.728 32.3985 200.255 28.7392V28.7288Z" fill="#F9FBFC"/>
                    <path d="M268.897 16.8284C271.109 21.2215 272.2 25.1744 272.844 28.6554C269.167 27.9109 265.418 27.2084 261.575 26.6213C263.662 35.827 260.432 44.9489 260.411 54.2386C259.882 63.7379 262.551 73.4155 264.618 88.5558C261.44 88.2098 258.199 87.9057 254.886 87.6645C252.04 73.7196 249.89 63.717 250.191 53.8611C250.129 44.1626 252.518 34.6213 250.295 25.3316C246.276 25.0485 242.173 24.9752 238.009 25.1849C237.032 21.9765 235.454 18.7157 232.66 15.5282C245.403 13.924 257.721 15.004 268.897 16.8284Z" fill="#F9FBFC"/>
                    <path d="M285.598 70.0289C286.055 75.1036 287.976 78.4378 291.248 78.8362C294.53 79.329 296.036 76.3408 295.859 71.2661C294.603 50.6842 304.355 47.0774 294.27 21.4103C297.48 21.9555 300.689 22.4588 303.919 22.8887C313.838 48.5033 305.747 52.2779 305.238 71.8637C305.155 77.9974 303.96 83.9843 301.842 88.1573C299.733 92.3618 296.753 94.4063 292.141 93.7353C287.54 93.1062 283.843 89.9712 281.101 85.1901C278.379 80.4299 276.323 74.5689 275.575 68.3513C271.94 47.9686 283.438 41.5833 273.124 17.5309C276.697 18.1599 280.187 18.8205 283.645 19.4706C293.782 44.6449 282.814 49.1848 285.598 70.0184V70.0289Z" fill="#F9FBFC"/>
                    <path d="M327.724 24.8703C333.177 25.0171 337.58 27.3867 341.174 31.3185C344.736 35.2294 347.707 40.4299 348.455 46.1862C350.127 58.2858 345.733 66.4326 343.812 77.9765C342.867 83.5335 341.579 88.2202 339.658 91.3343C337.715 94.4588 334.755 96.1049 329.396 95.9791C323.767 95.8113 318.283 95.4443 312.976 94.9306C310.556 75.4706 312.519 66.3172 314.586 56.9752C317.618 47.2346 316.195 41.7196 310.94 23.717C316.403 24.2831 321.98 24.6606 327.734 24.8598L327.724 24.8703ZM321.648 82.5164C323.518 82.8205 325.418 83.1246 327.319 83.4077C328.981 83.6593 330.279 83.2084 331.255 82.118C332.232 81.0381 332.834 79.1613 333.177 76.6868C334.724 64.2307 339.803 56.9856 337.747 45.0643C337.362 42.7261 336.417 40.8598 335.181 39.6645C333.945 38.4588 332.429 37.7982 330.705 37.6934C328.721 37.5675 326.748 37.4312 324.785 37.2635C330.798 55.4653 321.378 61.3369 321.648 82.506V82.5164Z" fill="#F9FBFC"/>
                    <path d="M364.107 24.5138C366.547 41.4679 367.222 53.1062 365 64.1259C363.338 74.9568 358.799 85.1796 363.764 94.8257C360.066 95.1508 356.359 95.4234 352.651 95.6331C347.281 83.4181 352.516 73.4994 354.261 63.1927C356.639 52.7497 355.517 41.9293 351.965 24.8703C355.933 24.7759 359.983 24.6396 364.096 24.5033L364.107 24.5138Z" fill="#F9FBFC"/>
                    <path d="M377.214 29.4103C380.278 25.101 385.305 22.679 391.661 22.8153C398.017 22.9411 403.19 25.08 406.638 27.9634C410.065 30.8991 411.436 34.7366 410.875 40.8388C409.556 54.1232 408.071 68.2569 406.742 78.6894C406.098 83.5649 403.667 86.8572 400.333 89.059C396.958 91.2713 393.115 92.3198 387.839 92.8755C382.604 93.3893 377.796 93.1796 374.025 91.3133C370.266 89.4784 368.427 85.8926 369.144 80.9961C370.795 70.3959 373.807 59.198 373.506 45.5256C373.381 39.2451 374.119 33.7091 377.214 29.4208V29.4103ZM386.779 86.5741C390.955 86.2281 393.146 84.4876 393.52 80.7445C394.465 70.5007 396.594 56.6187 397.997 42.2648C398.443 37.0643 396.21 34.7156 391.786 35.1246C387.34 35.523 385.367 38.5322 385.211 43.827C384.598 58.3172 382.033 71.1298 380.818 81.5728C380.423 85.3788 382.625 86.8886 386.779 86.5741Z" fill="#F9FBFC"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_63_746">
                    <rect width="411" height="96" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
            </Box>
            <Box display={{xs: "none", sm: "block", md: "none"}}>
                <svg width="451" height="106" viewBox="0 0 451 106" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_60_402)">
                    <path d="M5.35649 18.2456C8.80976 13.6147 13.0836 9.6322 19.3177 6.83054C25.5063 4.06361 31.2504 3.7163 35.9915 5.47602C40.6984 7.2589 43.6502 10.9751 43.6046 16.2775C43.5932 27.7388 38.9775 41.0525 38.533 56.45C38.3507 63.5352 37.9859 69.4048 35.9345 73.5147C33.9172 77.6709 29.9739 79.639 23.66 79.8705C17.3689 80.1252 12.0807 78.7012 8.55903 75.7259C5.01458 72.739 3.17967 68.5018 2.62122 63.0606C1.45873 51.2288 0.535577 41.6313 0.022714 32.1381C-0.239416 27.7851 1.9602 22.8996 5.36788 18.2456H5.35649ZM21.3122 67.4599C25.4379 67.0084 26.9423 63.9751 26.9195 58.395C27.1247 43.2174 30.7945 29.4522 30.0879 18.0603C29.8371 13.9273 26.8853 12.6538 22.1328 14.4946C17.3347 16.428 15.249 19.5075 15.534 23.3511C16.4001 34.0136 14.4399 46.5979 14.702 60.5252C14.8501 65.6075 17.2549 67.9345 21.3236 67.4599H21.3122Z" fill="#F9FBFC"/>
                    <path d="M60.5063 80.2757C57.0075 79.8937 53.4744 79.5695 49.8958 79.3033C36.5271 44.6067 61.6346 20.9778 47.5252 0.289488C52.9045 -0.150442 58.2155 -0.0809791 63.3783 0.32422C77.6017 16.1964 74.308 28.4797 74.1371 44.051C78.3653 28.7576 86.3318 19.0097 75.8124 1.88713C79.3341 2.47756 82.7873 3.14903 86.195 3.84366C95.2214 20.1442 91.6656 27.2294 87.1182 38.957C82.662 50.291 79.1859 63.6626 86.6623 83.7141C82.7418 83.1469 78.8326 82.5796 74.9121 82.047C59.3894 53.8105 66.6607 35.6344 63.5037 19.056C61.3155 34.743 50.2149 54.0189 60.5405 80.2642L60.5063 80.2757Z" fill="#F9FBFC"/>
                    <path d="M105.467 7.90722C114.961 26.1759 112.875 32.717 107.849 43.7847C103.325 54.3893 98.4468 66.4874 106.561 86.4232C102.629 85.9601 98.7431 85.4391 94.9023 84.9066C87.2436 64.6698 91.2097 51.8655 95.7685 40.8904C100.567 29.4869 103.575 22.8185 94.3325 5.5918C98.0821 6.4022 101.786 7.18944 105.467 7.9188V7.90722Z" fill="#F9FBFC"/>
                    <path d="M137.686 85.0571C136.9 83.8878 135.817 81.7113 135.27 78.5623C134.507 74.1167 134.871 69.8795 135.681 66.1053C136.41 62.8059 136.581 60.5483 135.783 58.9854C134.997 57.4341 133.23 56.3458 130.427 55.6744C129.025 55.3386 127.623 55.0029 126.233 54.6556C123.03 62.7827 119.486 75.4133 127.463 88.2639C123.326 88.044 119.246 87.7314 115.234 87.3609C106.63 68.085 112.271 56.4037 116.648 45.9727C121.731 35.0671 123.087 28.2598 113.491 9.38909C119.018 10.3268 124.534 11.0678 130.073 11.5656C135.749 12.0402 140.878 14.4599 145.083 18.6045C149.232 22.7259 152.958 28.5724 154.178 35.0092C154.577 37.1741 154.782 39.2233 154.816 41.1798C154.816 48.8323 150.417 52.4907 144.513 53.4053C146.986 54.9566 148.434 56.9594 148.867 59.4717C149.311 61.9955 148.89 65.0171 148.103 68.8144C147.397 72.3454 147.112 76.1774 147.978 80.0094C148.457 82.1281 149.311 83.8183 150.234 85.0223C151.158 86.2379 152.514 87.3609 154.36 88.3102C149.95 88.5186 145.562 88.6228 141.22 88.6228C139.613 87.4535 138.484 86.2264 137.698 85.0455L137.686 85.0571ZM129.982 45.9959C131.544 46.3779 133.093 46.7484 134.643 47.1073C137.025 47.663 138.997 47.582 140.49 46.8295C141.972 46.0769 143.054 44.5025 143.362 41.9439C143.624 39.7327 143.624 37.4057 143.294 34.8935C142.929 32.1265 141.858 29.8574 140.558 28.364C139.248 26.8589 137.572 25.9444 135.635 25.6665C133.868 25.4118 132.091 25.1224 130.301 24.8214C133.287 33.5852 132.489 39.9527 129.982 45.9959Z" fill="#F9FBFC"/>
                    <path d="M165.301 12.1213C172.345 31.9413 175.103 44.7803 172.367 56.8089C170.817 68.4324 164.811 79.1296 174.567 86.7126C170.191 87.1873 165.814 87.5925 161.46 87.8935C150.918 77.4625 158.053 66.6263 160.469 55.5354C164.184 43.9468 162.03 32.6707 153.95 12.4223C157.711 12.376 161.495 12.2602 165.29 12.1097L165.301 12.1213Z" fill="#F9FBFC"/>
                    <path d="M221.01 69.2196C221.819 74.2556 220.052 77.4972 216.861 79.5811C213.613 81.6766 209.362 82.6606 203.082 83.4826C196.859 84.2582 190.865 84.5477 185.816 83.2626C180.79 81.9891 177.713 78.9096 177.929 74.1399C178.545 63.9983 182.568 51.5876 179.388 36.0627C178.043 28.8502 177.188 22.4828 178.715 17.6088C180.186 12.7117 183.958 9.91006 189.543 10.0027C195.116 10.0837 199.891 12.5033 203.755 15.5018C207.607 18.5813 210.342 22.4944 212.188 29.0239C212.918 31.6287 213.647 34.303 214.342 36.9889C210.559 38.4245 206.866 39.6401 203.242 40.6357C202.729 37.5678 202.113 34.4651 201.418 31.3624C200.119 25.6897 197.361 23.2237 193.554 23.8257C189.702 24.4162 188.802 27.8546 190.055 33.7936C193.611 50.0247 191.469 64.4961 191.799 74.2788C192.027 77.8098 195.104 78.9212 200.051 78.3655C205.042 77.7403 207.185 76.0964 206.706 72.5538C206.364 69.9258 206.125 66.9273 205.863 63.6394C210.239 62.7711 214.741 61.6482 219.494 60.2242C220.086 63.4658 220.599 66.499 221.032 69.2312L221.01 69.2196Z" fill="#F9FBFC"/>
                    <path d="M220.052 99.0306C215.767 99.76 211.504 100.663 207.47 101.763C206.718 98.3823 205.692 95.2218 204.575 92.177C208.655 90.8109 212.872 89.63 217.089 88.5997C217.967 91.9918 219.049 95.4186 220.041 99.0306H220.052Z" fill="#F9FBFC"/>
                    <path d="M219.744 31.7213C221.443 27.7157 224.736 24.3467 231.039 22.066C237.273 19.8085 243.86 19.878 249.285 22.3092C254.71 24.7403 258.198 29.2786 258.904 35.1829C259.007 35.947 259.087 36.7111 259.155 37.4867C255.212 37.753 251.234 38.2393 247.223 38.9918C247.063 38.0077 246.881 37.0237 246.664 36.0512C246.128 33.6316 244.989 31.9992 243.45 31.1888C241.912 30.3784 239.997 30.2626 237.809 30.8994C235.62 31.5361 234.196 32.5896 233.489 33.9789C232.771 35.3797 232.851 37.1857 233.501 39.4664C234.298 42.2681 235.723 44.4793 237.877 46.1927C240.031 47.9061 242.994 49.8164 246.573 52.1549C249.548 54.1809 251.804 56.1722 253.514 57.9782C255.223 59.7843 256.648 62.0881 257.89 64.9245C259.132 67.7609 259.828 71.1298 260.204 75.066C260.83 81.7576 260.739 87.2567 258.79 91.1582C256.853 95.0944 253.035 97.0625 246.881 97.2593C240.738 97.4909 235.222 96.0901 230.902 93.3116C226.594 90.5099 223.676 86.5158 222.879 80.8314C222.685 79.3959 222.525 77.9487 222.389 76.5132C226.742 75.3555 230.959 74.3714 235.039 73.5379C235.096 75.3323 235.199 77.1383 235.381 78.9444C235.883 83.8415 238.8 85.9717 243.108 85.5086C247.473 85.0455 248.841 82.2323 248.351 77.011C248.043 73.6536 247.131 70.852 245.365 68.6755C243.587 66.499 241 64.1951 237.33 62.0534C234.276 60.201 231.62 58.7307 229.489 57.492C227.358 56.2532 225.386 54.6556 223.505 52.7222C221.614 50.7888 220.303 48.3692 219.46 45.3592C218.012 40.2768 218.069 35.7733 219.744 31.7329V31.7213Z" fill="#F9FBFC"/>
                    <path d="M295.067 18.5813C297.494 23.4321 298.691 27.7967 299.398 31.6403C295.363 30.8183 291.249 30.0427 287.032 29.3943C289.323 39.559 285.778 49.6311 285.756 59.8884C285.174 70.3773 288.103 81.063 290.371 97.7803C286.884 97.3983 283.328 97.0625 279.692 96.7963C276.57 81.3987 274.21 70.3541 274.541 59.4717C274.473 48.7628 277.094 38.2277 274.655 27.9704C270.244 27.6578 265.743 27.5767 261.172 27.8083C260.101 24.2657 258.369 20.6652 255.303 17.1458C269.287 15.3745 282.804 16.5669 295.067 18.5813Z" fill="#F9FBFC"/>
                    <path d="M313.393 77.3236C313.895 82.9269 316.003 86.6084 319.593 87.0483C323.195 87.5925 324.847 84.293 324.653 78.6897C323.274 55.9638 333.976 51.9813 322.91 23.6405C326.431 24.2425 329.953 24.7982 333.497 25.2729C344.382 53.5558 335.503 57.7235 334.945 79.3496C334.854 86.1222 333.543 92.7327 331.218 97.3404C328.904 101.983 325.634 104.24 320.573 103.499C315.524 102.805 311.467 99.3432 308.458 94.0641C305.472 88.8081 303.216 82.3364 302.395 75.4712C298.406 52.9653 311.023 45.9149 299.705 19.357C303.626 20.0516 307.455 20.781 311.251 21.4988C322.374 49.2954 310.339 54.3083 313.393 77.312V77.3236Z" fill="#F9FBFC"/>
                    <path d="M359.619 27.461C365.603 27.623 370.435 30.2395 374.378 34.5809C378.287 38.8991 381.547 44.6414 382.368 50.9972C384.202 64.3572 379.382 73.3526 377.273 86.099C376.236 92.2349 374.823 97.4098 372.714 100.848C370.583 104.298 367.335 106.116 361.454 105.977C355.277 105.792 349.259 105.386 343.436 104.819C340.78 83.3321 342.934 73.2253 345.202 62.9101C348.53 52.1549 346.969 46.0654 341.202 26.1875C347.197 26.8126 353.317 27.2294 359.631 27.4494L359.619 27.461ZM352.952 91.1119C355.003 91.4476 357.089 91.7834 359.175 92.096C360.998 92.3738 362.423 91.876 363.494 90.672C364.565 89.4795 365.227 87.4072 365.603 84.675C367.301 70.9214 372.874 62.9216 370.617 49.7585C370.196 47.1768 369.158 45.1161 367.802 43.7963C366.446 42.4649 364.782 41.7355 362.89 41.6198C360.713 41.4808 358.548 41.3303 356.394 41.1451C362.993 61.243 352.656 67.7261 352.952 91.1003V91.1119Z" fill="#F9FBFC"/>
                    <path d="M399.543 27.0673C402.221 45.7875 402.962 58.6381 400.523 70.8057C398.699 82.7648 393.719 94.0525 399.167 104.703C395.109 105.062 391.041 105.363 386.972 105.595C381.08 92.1075 386.824 81.1556 388.738 69.7753C391.348 58.2445 390.117 46.2969 386.22 27.461C390.573 27.3568 395.018 27.2063 399.531 27.0558L399.543 27.0673Z" fill="#F9FBFC"/>
                    <path d="M413.926 32.4738C417.288 27.7157 422.804 25.0413 429.779 25.1918C436.754 25.3308 442.429 27.6925 446.213 30.8762C449.974 34.1178 451.479 38.355 450.863 45.0929C449.416 59.7611 447.786 75.367 446.327 86.8863C445.621 92.2696 442.954 95.9048 439.295 98.336C435.591 100.779 431.374 101.937 425.585 102.55C419.841 103.117 414.564 102.886 410.427 100.825C406.301 98.7991 404.284 94.8397 405.07 89.4332C406.882 77.7288 410.187 65.3644 409.857 50.2679C409.72 43.3332 410.529 37.2205 413.926 32.4854V32.4738ZM424.422 95.5922C429.004 95.2102 431.409 93.2884 431.819 89.1554C432.856 77.8445 435.192 62.5164 436.731 46.6674C437.221 40.9251 434.771 38.3319 429.916 38.7834C425.038 39.2233 422.872 42.5459 422.701 48.3924C422.029 64.3919 419.214 78.5392 417.88 90.07C417.447 94.2724 419.863 95.9395 424.422 95.5922Z" fill="#F9FBFC"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_60_402">
                    <rect width="451" height="106" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
            </Box>
            <Box display={{xs: "block", sm: "none"}}>
                <svg width="327" height="76" viewBox="0 0 327 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_23_594)">
                    <path d="M3.88376 13.0817C6.38757 9.7615 9.48636 6.90611 14.0064 4.89737C18.4935 2.91353 22.6582 2.66452 26.0958 3.9262C29.5086 5.20449 31.6488 7.86897 31.6158 11.6706C31.6075 19.8882 28.2608 29.4338 27.9386 40.4736C27.8064 45.5536 27.5419 49.7619 26.0545 52.7086C24.5919 55.6885 21.7327 57.0996 17.1548 57.2657C12.5934 57.4483 8.75918 56.4273 6.20578 54.2941C3.63586 52.1525 2.30545 49.1145 1.90054 45.2132C1.05767 36.7301 0.388332 29.8489 0.016478 23.0424C-0.173581 19.9214 1.42126 16.4186 3.89202 13.0817H3.88376ZM15.4525 48.3674C18.4439 48.0437 19.5347 45.869 19.5181 41.8681C19.6669 30.9861 22.3277 21.1167 21.8154 12.9489C21.6336 9.98562 19.4934 9.07256 16.0475 10.3923C12.5686 11.7785 11.0564 13.9865 11.263 16.7423C11.891 24.3871 10.4697 33.4098 10.6598 43.3954C10.7672 47.0394 12.5108 48.7078 15.4608 48.3674H15.4525Z" fill="#F9FBFC"/>
                    <path d="M43.8704 57.5562C41.3336 57.2823 38.7719 57.0499 36.1772 56.8589C26.4842 31.9821 44.6885 15.0407 34.4584 0.207558C38.3587 -0.107864 42.2095 -0.0580607 45.9528 0.232459C56.2656 11.6125 53.8774 20.4194 53.7535 31.5837C56.8192 20.6187 62.5953 13.6296 54.9682 1.35304C57.5216 1.77636 60.0254 2.2578 62.4962 2.75583C69.0408 14.443 66.4626 19.523 63.1655 27.9315C59.9345 36.0577 57.4142 45.6449 62.835 60.0215C59.9924 59.6147 57.158 59.208 54.3154 58.8262C43.0606 38.5811 48.3327 25.5492 46.0437 13.6628C44.4571 24.91 36.4086 38.7305 43.8952 57.5479L43.8704 57.5562Z" fill="#F9FBFC"/>
                    <path d="M76.4696 5.66933C83.3531 18.7676 81.8409 23.4574 78.1967 31.3928C74.9161 38.9961 71.3794 47.6702 77.2629 61.9638C74.412 61.6318 71.5942 61.2582 68.8094 60.8764C63.2564 46.367 66.1321 37.1866 69.4375 29.3176C72.9164 21.1416 75.0979 16.3605 68.3963 4.00922C71.1149 4.59026 73.8006 5.15469 76.4696 5.67763V5.66933Z" fill="#F9FBFC"/>
                    <path d="M99.8303 60.9843C99.2601 60.146 98.4751 58.5855 98.0785 56.3277C97.5248 53.1403 97.7893 50.1023 98.376 47.3963C98.9048 45.0306 99.0288 43.412 98.4503 42.2914C97.8802 41.1792 96.5993 40.3989 94.5665 39.9175C93.5501 39.6768 92.5337 39.436 91.5256 39.187C89.2036 45.014 86.6336 54.0699 92.418 63.2836C89.4184 63.1259 86.4601 62.9017 83.5514 62.6361C77.3125 48.8157 81.4029 40.4404 84.576 32.9616C88.2615 25.1425 89.2449 20.2617 82.2871 6.7318C86.2948 7.40415 90.2943 7.93538 94.3104 8.29231C98.4255 8.63263 102.144 10.3674 105.193 13.3391C108.201 16.2941 110.903 20.4858 111.787 25.101C112.077 26.6532 112.225 28.1224 112.25 29.5252C112.25 35.0118 109.061 37.6348 104.78 38.2906C106.573 39.4028 107.623 40.8388 107.937 42.6401C108.259 44.4496 107.953 46.616 107.383 49.3386C106.871 51.8703 106.664 54.6178 107.292 57.3653C107.639 58.8843 108.259 60.0962 108.928 60.9594C109.598 61.831 110.581 62.6361 111.92 63.3168C108.722 63.4662 105.54 63.5409 102.392 63.5409C101.227 62.7025 100.409 61.8227 99.8386 60.976L99.8303 60.9843ZM94.2443 32.9782C95.3763 33.2521 96.5002 33.5177 97.624 33.7751C99.351 34.1735 100.781 34.1154 101.863 33.5758C102.937 33.0363 103.722 31.9074 103.946 30.073C104.136 28.4876 104.136 26.8192 103.896 25.018C103.632 23.0341 102.855 21.4072 101.913 20.3364C100.962 19.2574 99.7477 18.6016 98.3429 18.4024C97.0621 18.2198 95.773 18.0123 94.4756 17.7965C96.6406 24.08 96.0622 28.6453 94.2443 32.9782Z" fill="#F9FBFC"/>
                    <path d="M119.853 8.69074C124.959 22.9013 126.959 32.1066 124.976 40.7309C123.852 49.0647 119.497 56.7344 126.571 62.1713C123.398 62.5116 120.224 62.8021 117.068 63.018C109.424 55.5391 114.597 47.7698 116.349 39.8179C119.043 31.509 117.481 23.4242 111.622 8.90655C114.349 8.87335 117.093 8.79035 119.844 8.68244L119.853 8.69074Z" fill="#F9FBFC"/>
                    <path d="M160.244 49.6291C160.831 53.2399 159.55 55.564 157.236 57.0581C154.881 58.5605 151.799 59.2661 147.246 59.8554C142.734 60.4116 138.387 60.6191 134.727 59.6977C131.083 58.7847 128.851 56.5767 129.008 53.1569C129.455 45.8856 132.372 36.9874 130.066 25.8563C129.091 20.6851 128.471 16.1197 129.579 12.6252C130.645 9.11406 133.38 7.10533 137.429 7.17173C141.47 7.22983 144.932 8.96465 147.733 11.1145C150.526 13.3225 152.51 16.128 153.848 20.8096C154.377 22.6772 154.906 24.5946 155.41 26.5204C152.667 27.5496 149.989 28.4212 147.362 29.135C146.99 26.9354 146.543 24.7108 146.039 22.4863C145.097 18.419 143.098 16.651 140.338 17.0826C137.545 17.5059 136.892 19.9712 137.801 24.2294C140.379 35.8668 138.825 46.2425 139.065 53.2565C139.23 55.7882 141.461 56.585 145.048 56.1866C148.667 55.7384 150.221 54.5597 149.874 52.0197C149.626 50.1355 149.452 47.9856 149.262 45.6283C152.435 45.0057 155.699 44.2006 159.145 43.1796C159.575 45.5038 159.947 47.6785 160.261 49.6374L160.244 49.6291Z" fill="#F9FBFC"/>
                    <path d="M159.55 71.0031C156.443 71.526 153.352 72.1735 150.427 72.962C149.882 70.5383 149.138 68.2722 148.328 66.0892C151.287 65.1097 154.344 64.263 157.402 63.5243C158.038 65.9564 158.823 68.4133 159.542 71.0031H159.55Z" fill="#F9FBFC"/>
                    <path d="M159.327 22.7436C160.558 19.8716 162.946 17.4561 167.516 15.8209C172.036 14.2023 176.812 14.2521 180.746 15.9952C184.679 17.7384 187.208 20.9922 187.72 25.2255C187.794 25.7733 187.852 26.3211 187.902 26.8773C185.043 27.0682 182.159 27.4168 179.25 27.9564C179.134 27.2508 179.002 26.5453 178.845 25.848C178.457 24.1132 177.63 22.9428 176.515 22.3618C175.399 21.7807 174.011 21.6977 172.424 22.1543C170.838 22.6108 169.805 23.3661 169.293 24.3622C168.772 25.3666 168.83 26.6615 169.301 28.2967C169.879 30.3054 170.912 31.8908 172.474 33.1193C174.036 34.3478 176.184 35.7174 178.779 37.3941C180.936 38.8467 182.572 40.2744 183.811 41.5693C185.051 42.8642 186.084 44.516 186.985 46.5496C187.885 48.5833 188.389 50.9987 188.662 53.8209C189.117 58.6187 189.05 62.5614 187.637 65.3587C186.233 68.1809 183.464 69.592 179.002 69.7331C174.548 69.8991 170.549 68.8948 167.417 66.9026C164.293 64.8939 162.178 62.0302 161.599 57.9546C161.459 56.9253 161.343 55.8878 161.244 54.8585C164.401 54.0284 167.458 53.3229 170.416 52.7253C170.458 54.0118 170.532 55.3067 170.664 56.6016C171.028 60.1128 173.143 61.6401 176.267 61.308C179.432 60.976 180.423 58.959 180.068 55.2154C179.845 52.8083 179.184 50.7995 177.903 49.239C176.614 47.6785 174.738 46.0267 172.077 44.4911C169.863 43.163 167.937 42.1088 166.392 41.2207C164.847 40.3325 163.417 39.187 162.054 37.8008C160.682 36.4146 159.732 34.6798 159.12 32.5217C158.071 28.8777 158.112 25.6488 159.327 22.7519V22.7436Z" fill="#F9FBFC"/>
                    <path d="M213.94 13.3225C215.7 16.8004 216.568 19.9297 217.08 22.6855C214.155 22.0962 211.172 21.54 208.114 21.0752C209.775 28.3631 207.205 35.5846 207.189 42.9389C206.767 50.4592 208.891 58.1206 210.535 70.1066C208.007 69.8327 205.429 69.592 202.793 69.4011C200.528 58.3613 198.818 50.4426 199.057 42.6401C199.008 34.962 200.908 27.4085 199.14 20.0542C195.942 19.8301 192.678 19.772 189.364 19.938C188.588 17.398 187.332 14.8166 185.109 12.2932C195.248 11.0232 205.048 11.8782 213.94 13.3225Z" fill="#F9FBFC"/>
                    <path d="M227.227 55.4395C227.591 59.457 229.12 62.0966 231.723 62.412C234.334 62.8021 235.532 60.4365 235.392 56.419C234.392 40.125 242.151 37.2696 234.127 16.9498C236.681 17.3814 239.234 17.7799 241.804 18.1202C249.696 38.3985 243.258 41.3867 242.854 56.8921C242.787 61.748 241.837 66.4876 240.151 69.7912C238.474 73.1198 236.102 74.7384 232.433 74.2071C228.773 73.7091 225.831 71.2272 223.649 67.4422C221.484 63.6737 219.848 59.0337 219.253 54.1114C216.361 37.9751 225.509 32.9201 217.303 13.8786C220.146 14.3766 222.922 14.8996 225.674 15.4142C233.739 35.3439 225.013 38.938 227.227 55.4312V55.4395Z" fill="#F9FBFC"/>
                    <path d="M260.744 19.689C265.082 19.8052 268.586 21.6811 271.445 24.7938C274.279 27.89 276.643 32.007 277.238 36.564C278.568 46.1429 275.073 52.5924 273.544 61.7314C272.792 66.1307 271.767 69.841 270.239 72.3063C268.693 74.7799 266.338 76.0831 262.074 75.9834C257.596 75.8506 253.232 75.5601 249.01 75.1534C247.084 59.7475 248.646 52.5011 250.291 45.1053C252.704 37.3941 251.571 33.028 247.39 18.7759C251.737 19.2242 256.174 19.523 260.752 19.6807L260.744 19.689ZM255.91 65.3255C257.397 65.5662 258.909 65.8069 260.422 66.0311C261.744 66.2303 262.777 65.8734 263.553 65.0101C264.33 64.1551 264.809 62.6693 265.082 60.7104C266.313 50.8493 270.354 45.1136 268.718 35.6759C268.412 33.8249 267.66 32.3474 266.677 31.4011C265.694 30.4465 264.487 29.9236 263.115 29.8406C261.537 29.741 259.967 29.6331 258.405 29.5003C263.19 43.91 255.695 48.5584 255.91 65.3172V65.3255Z" fill="#F9FBFC"/>
                    <path d="M289.691 19.4068C291.633 32.8288 292.17 42.0424 290.401 50.7663C289.079 59.3408 285.468 67.4339 289.418 75.0704C286.476 75.3277 283.526 75.5435 280.576 75.7095C276.304 66.0394 280.469 58.187 281.857 50.0276C283.749 41.7602 282.857 33.194 280.031 19.689C283.187 19.6143 286.41 19.5064 289.682 19.3985L289.691 19.4068Z" fill="#F9FBFC"/>
                    <path d="M300.119 23.2831C302.557 19.8716 306.556 17.9542 311.613 18.0621C316.671 18.1617 320.786 19.855 323.529 22.1377C326.256 24.4618 327.347 27.4998 326.901 32.3308C325.851 42.8476 324.67 54.0367 323.612 62.2958C323.1 66.1556 321.166 68.7619 318.513 70.5051C315.828 72.2565 312.77 73.0866 308.573 73.5265C304.408 73.9332 300.582 73.7672 297.582 72.2897C294.591 70.8371 293.128 67.9983 293.698 64.1219C295.012 55.7301 297.409 46.8651 297.169 36.0411C297.07 31.0691 297.657 26.6864 300.119 23.2914V23.2831ZM307.73 68.5378C311.052 68.2639 312.795 66.886 313.093 63.9227C313.845 55.8131 315.539 44.8231 316.654 33.4596C317.01 29.3426 315.233 27.4832 311.713 27.8069C308.176 28.1224 306.606 30.5046 306.482 34.6964C305.994 46.1678 303.953 56.3111 302.986 64.5785C302.672 67.5916 304.424 68.7868 307.73 68.5378Z" fill="#F9FBFC"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_23_594">
                    <rect width="327" height="76" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
            </Box>
        </>
    )
}

export default Logo;