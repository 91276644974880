import { Box, Input, Textarea, Typography, useTheme } from "@mui/joy";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

const ContactUs = () => {

    const theme = useTheme();
    const { t } = useTranslation();

    const ref = useRef(null);
    const [isFixed, setIsFixed] = useState(false);

    const [componentHeight, setComponentHeight] = useState(0);

    useEffect(() => {
        const handleComponentHeight = () => {
            if (ref.current) {
                const height = ref.current.clientHeight;
                setComponentHeight(height);
            }
        };

        handleComponentHeight(); // Calcula la altura del componente al inicio
    }, []);

    useEffect(() => {
        const handleScrollFixed = () => {
            if (ref.current) {
                const { top } = ref.current.getBoundingClientRect();
                const isInView = top <= 206 && Math.abs(top) <= componentHeight - getBreakpointValue();
                setIsFixed(isInView);
            }
        };

        window.addEventListener('scroll', handleScrollFixed);
        handleScrollFixed(); // Llama al método al inicio para establecer la posición inicial
        return () => {
            window.removeEventListener('scroll', handleScrollFixed);
        };
    }, [componentHeight]);

    const getBreakpointValue = () => {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (width <= theme.breakpoints.values.xs) {
            return 600;
        }
        else if (width <= theme.breakpoints.values.sm) {
            return 670;
        }
        else if (width <= theme.breakpoints.values.md) {
            return 700;
        }
        else if (width <= theme.breakpoints.values.lg) {
            return 550;
        }
        else if (width <= theme.breakpoints.values.xl) {
            return 650;
        }
        else {
            return 650;
        }
    };


    return (
        <>
            <Box sx={{ position: "relative", }} ref={ref}>
                <Box m={0} p={0} px={0} display="flex" justifyContent="center" alignItems="center"
                    sx={{
                        width: "100vw",
                        height: { xs: "150dvh" },
                        backgroundColor: "white",
                        maxWidth: "100vw"
                    }}>
                    <Box sx={{
                        height: { xs: "161px", sm: "161px" },
                        width: { xs: "65%", sm: "455px" },
                        p: 4,
                        backgroundColor: "rgba(240, 240, 240, 0.5)",
                        backdropFilter: "blur(10px) saturate(2)",
                        position: "fixed",
                        zIndex: 10,
                        top: "55%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        transition: "opacity 1s ease",
                        pointerEvents: isFixed ? "auto" : "none",
                        opacity: isFixed ? 1 : 0,
                    }}>
                        <Box>
                            <Box>
                                <Typography fontFamily="Raleway" sx={{ fontWeight: 800, fontSize: "24px", textAlign: "start", lineHeight: "28px", color: theme.vars.palette.danger['500'] }}>
                                    {t('landing.gaming.contactUs.contact')}
                                </Typography>
                            </Box>
                            <Box mt={1}>
                                <Typography fontFamily="Raleway" sx={{ color: theme.vars.palette.text.secondary, fontWeight: 500, fontSize: { xs: "14px", md: "14px" }, textAlign: "start" }}>
                                    {t('landing.gaming.contactUs.description')}
                                </Typography>
                            </Box>
                            {
                                false && (
                                    <Box mt={1}>
                                        <Box>
                                            <Typography fontFamily="Raleway" sx={{ color: "#202223", fontWeight: 500, fontSize: { xs: "12px", md: "12px" }, textAlign: "start" }}>
                                                {t('landing.gaming.contactUs.name')}
                                            </Typography>
                                        </Box>
                                        <Box mb={1}>
                                            <Input
                                                value={""}
                                                type="text"
                                                placeholder={""}
                                                onChange={(e) => { }}
                                                sx={{
                                                    display: "flex",
                                                    padding: "8px 16px",
                                                    justifyContent: "center",
                                                    textAlign: "center",
                                                    alignItems: "center",
                                                    color: "black",
                                                    gap: "8px",
                                                    borderColor: "#D6DCFF",
                                                    backgroundColor: "white",
                                                    fontFamily: "Raleway",
                                                    fontSize: "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: 700,
                                                    lineHeight: "120%",
                                                    borderRadius: '0px',
                                                    boxShadow: 'none'
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography fontFamily="Raleway" sx={{ color: "#202223", fontWeight: 500, fontSize: { xs: "12px", md: "12px" }, textAlign: "start" }}>
                                                {t('landing.gaming.contactUs.lastName')}
                                            </Typography>
                                        </Box>
                                        <Box mb={1}>
                                            <Input
                                                value={""}
                                                type="text"
                                                placeholder={""}
                                                onChange={(e) => { }}
                                                sx={{
                                                    display: "flex",
                                                    padding: "8px 16px",
                                                    justifyContent: "center",
                                                    textAlign: "center",
                                                    alignItems: "center",
                                                    color: "black",
                                                    gap: "8px",
                                                    borderColor: "#D6DCFF",
                                                    backgroundColor: "white",
                                                    fontFamily: "Raleway",
                                                    fontSize: "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: 700,
                                                    lineHeight: "120%",
                                                    borderRadius: '0px',
                                                    boxShadow: 'none'
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography fontFamily="Raleway" sx={{ color: "#202223", fontWeight: 500, fontSize: { xs: "12px", md: "12px" }, textAlign: "start" }}>
                                                Email
                                            </Typography>
                                        </Box>
                                        <Box mb={1}>
                                            <Input
                                                value={""}
                                                type="email"
                                                placeholder={""}
                                                onChange={(e) => { }}
                                                sx={{
                                                    display: "flex",
                                                    padding: "8px 16px",
                                                    justifyContent: "center",
                                                    textAlign: "center",
                                                    alignItems: "center",
                                                    color: "black",
                                                    gap: "8px",
                                                    borderColor: "#D6DCFF",
                                                    backgroundColor: "white",
                                                    fontFamily: "Raleway",
                                                    fontSize: "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: 700,
                                                    lineHeight: "120%",
                                                    borderRadius: '0px',
                                                    boxShadow: 'none'
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography fontFamily="Raleway" sx={{ color: "#202223", fontWeight: 500, fontSize: { xs: "12px", md: "12px" }, textAlign: "start" }}>
                                                {t('landing.gaming.contactUs.phone')}
                                            </Typography>
                                        </Box>
                                        <Box mb={1}>
                                            <Input
                                                value={""}
                                                type="phone"
                                                placeholder={""}
                                                onChange={(e) => { }}
                                                sx={{
                                                    display: "flex",
                                                    padding: "8px 16px",
                                                    justifyContent: "center",
                                                    textAlign: "center",
                                                    alignItems: "center",
                                                    color: "black",
                                                    gap: "8px",
                                                    borderColor: "#D6DCFF",
                                                    backgroundColor: "white",
                                                    fontFamily: "Raleway",
                                                    fontSize: "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: 700,
                                                    lineHeight: "120%",
                                                    borderRadius: '0px',
                                                    boxShadow: 'none'
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography fontFamily="Raleway" sx={{ color: "#202223", fontWeight: 500, fontSize: { xs: "12px", md: "12px" }, textAlign: "start" }}>
                                                {t('landing.gaming.contactUs.hearYou')}
                                            </Typography>
                                        </Box>
                                        <Box mb={1}>
                                            <Textarea
                                                value={""}
                                                type="text"
                                                placeholder={""}
                                                onChange={(e) => { }}
                                                sx={{
                                                    display: "flex",
                                                    padding: "8px 16px",
                                                    justifyContent: "center",
                                                    textAlign: "center",
                                                    alignItems: "center",
                                                    color: "black",
                                                    gap: "8px",
                                                    borderColor: "#D6DCFF",
                                                    backgroundColor: "white",
                                                    fontFamily: "Raleway",
                                                    fontSize: "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: 700,
                                                    lineHeight: "120%",
                                                    height: "100px",
                                                    borderRadius: '0px',
                                                    boxShadow: 'none'
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                )
                            }
                            <Box mt={2} sx={{
                                width: { xs: "86.5%", sm: "92.5%", md: "92.5%" },
                                backgroundColor: theme.vars.palette.background.body,
                                padding: "12px 16px 12px 16px",
                                display: "flex",
                                justifyContent: "center",
                                cursor: "pointer"
                            }} onClick={() => window.location.href = 'mailto:hey@oniric.studio'}>
                                <Typography fontFamily="Raleway" sx={{ color: "white", fontWeight: 800, fontSize: { xs: "13px", sm: "16px", md: "16px" }, textAlign: "center" }}>
                                    {t('landing.gaming.contactUs.experts')}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box display={{ xs: "block", md: "none" }} sx={{ zIndex: 5 }}>
                        <Box position="absolute" top={{ xs: "15%", sm: "15%" }} left={{ xs: "0%", sm: "45%" }}>
                            <img alt="" src="/images/cloud_mobile.png" />
                        </Box>
                        <Box position="absolute" top={{ xs: "35%", sm: "35%" }} left={{ xs: "65%", sm: "0%" }}>
                            <img alt="" src="/images/cloud_mobile.png" />
                        </Box>
                        <Box position="absolute" top={{ xs: "55%", sm: "55%" }} left={{ xs: "30%", sm: "15%" }}>
                            <img alt="" src="/images/cloud_mobile.png" />
                        </Box>
                        <Box position="absolute" top={{ xs: "75%", sm: "28%" }} left={{ xs: "0%", sm: "80%" }}>
                            <img alt="" src="/images/cloud_mobile.png" />
                        </Box>
                        <Box position="absolute" top={{ xs: "85%", sm: "38%" }} left={{ xs: "65%", sm: "35%" }}>
                            <img alt="" src="/images/cloud_mobile.png" />
                        </Box>
                        <Box position="absolute" top={{ xs: "95%", sm: "48%" }} left={{ xs: "-30%", sm: "45%" }}>
                            <img alt="" src="/images/cloud_mobile.png" />
                        </Box>
                    </Box>
                    <Box display={{ xs: "none", md: "block" }} >
                        <Box position="absolute" top={{ md: "5%", lg: "10%", xl: "10%" }} left={{ md: "10%", lg: "10%", xl: "10%" }}>
                            <img alt="" src="/images/cloud.png" />
                        </Box>
                        <Box position="absolute" top={{ md: "10%", lg: "15%", xl: "20%" }} left={{ md: "80%", lg: "80%", xl: "80%" }}>
                            <img alt="" src="/images/cloud.png" />
                        </Box>
                        <Box position="absolute" top={{ md: "40%", lg: "45%", xl: "50%" }} left={{ md: "15%", lg: "25%", xl: "10%" }}>
                            <img alt="" src="/images/cloud.png" />
                        </Box>
                        <Box position="absolute" top={{ md: "50%", lg: "65%", xl: "60%" }} left={{ md: "70%", lg: "0%", xl: "60%" }}>
                            <img alt="" src="/images/cloud.png" />
                        </Box>
                        <Box position="absolute" top={{ md: "70%", lg: "95%", xl: "80%" }} left={{ md: "90%", lg: "95%", xl: "80%" }}>
                            <img alt="" src="/images/cloud_eye.png" />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default ContactUs;