import { Box } from "@mui/joy";
import Header from "../components/UI/header";
import Business from "../components/landing/business"
import Rules from "../components/landing/rules"
import Embrace from "../components/landing/embrace"
import Services from "../components/landing/services"
import Base from "../components/landing/base"
import Partner from "../components/landing/partner"
import Brands from "../components/landing/brands"
import Footer from "../components/UI/footer";
import { useRef } from "react";
import { ParallaxProvider } from 'react-scroll-parallax';

const Landing = () => {
    const businessRef = useRef(null);

    const scrollToRef = (ref, duration = 800) => {
        const element = ref.current;

        if (element) {
            const startingY = window.pageYOffset;
            const targetY = element.getBoundingClientRect().top + window.pageYOffset;
            const diff = targetY - startingY;
            let start;

            function step(timestamp) {
                if (!start) start = timestamp;

                const timeElapsed = timestamp - start;
                const progress = Math.min(timeElapsed / duration, 1);
                window.scrollTo(0, startingY + diff * progress);

                if (timeElapsed < duration) {
                    requestAnimationFrame(step);
                }
            }

            requestAnimationFrame(step);
        }
    };

    const scrollToBusiness = () => {
        scrollToRef(businessRef, 50); 
    };

    return (
        <ParallaxProvider>
            <Box sx={{ backgroundColor: "white", overflowX: "hidden", scrollBehavior: "smooth" }}>
                <Header scrollToBusiness={scrollToBusiness} />
                <Box ref={businessRef}>
                    <Business />
                </Box>
                <Box>
                    <Rules />
                </Box>
                <Box>
                    <Embrace />
                </Box>
                <Box>
                    <Services />
                </Box>
                <Box>
                    <Base />
                </Box>
                <Box>
                    <Partner />
                </Box>
                {/* <Box>
                    <Word />
                </Box> */}
                <Box>
                    <Brands />
                </Box>
                <Box>
                    <Footer />
                </Box>
            </Box>
        </ParallaxProvider>
    )
};

export default Landing;