import { Box, Grid, Typography, useTheme } from "@mui/joy";
import { useTranslation } from "react-i18next";
import CardService from "../landing/services/card";
import { useRef, useState } from "react";

const Services = () => {

    const { t } = useTranslation();
    const theme = useTheme();
    const [select, setSelect] = useState(1);
    const carouselRef = useRef(null);

    const move = (newPosition) => {
        if (newPosition >= 1 && newPosition <= 7) {
            setSelect(newPosition);
            if (carouselRef.current) {
                const cardWidth = carouselRef.current.children[0].offsetWidth;
                const breakpointValue = getBreakpointValue(); 
                console.log(breakpointValue)
                const scrollAmount = (newPosition - 1) * cardWidth * breakpointValue; 
                carouselRef.current.scrollTo({
                    left: scrollAmount,
                    behavior: "smooth"
                });
            }
        }
    };

    const getBreakpointValue = () => {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (width < theme.breakpoints.values.sm) {
            return 1;
        }
        else if (width < theme.breakpoints.values.md) {
            return 0.75;
        }
        else if (width < theme.breakpoints.values.lg) {
            return 0.65;
        }
        else if (width < theme.breakpoints.values.xl) {
            return 0.65;
        }
        else {
            return 0.55;
        }
    };

    return (
        <>
            <Box sx={{ position: "relative", backgroundColor: "white"}}>
                <Box m={0} p={0} pt={{ xs: 8, md: 0 }} pb={{ xs: 8, lg: 12 }} display="flex" justifyContent="center" alignItems="center" sx={{ width: "100vw", height: { xs: "100%", sm: "100%" }, backgroundColor: theme.vars.palette.background.body }}>
                    <Box>
                        <Box mt="180px" display={{ xs: "none", lg: "block" }}>
                            <Grid container mt={6} columns={8} sx={{ flexGrow: 1 }}>
                                <Grid pl={8} sm={4}>
                                    <Box>
                                        <Typography mr={1} fontFamily="Raleway" sx={{ color: "white", fontWeight: 700, fontSize: "48px", textAlign: "start" }}>
                                            {t('landing.tailoredServicesTitle')}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography mr={1} fontFamily="Raleway" sx={{ color: "white", fontWeight: 700, fontSize: "24px", textAlign: "start" }}>
                                            {t('landing.tailoredServicesSubtitle')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid sm={4}>
                                    <Box sx={{ maxWidth: "750px" }}>
                                        <Typography mr={1} fontFamily="Raleway" sx={{ color: "white", fontWeight: 500, fontSize: "20px", textAlign: "start" }}>
                                            {t('landing.servicesDescription1')}
                                        </Typography>
                                    </Box>
                                    <Box mt={2} sx={{ maxWidth: "750px" }}>
                                        <Typography mr={1} fontFamily="Raleway" sx={{ color: "white", fontWeight: 500, fontSize: "20px", textAlign: "start" }}>
                                            {t('landing.servicesDescription2')}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box display={{ xs: "block", lg: "none" }} pl={{ xs: 0, md: 8 }} pt={{ xs: 0, md: 8 }}>
                            <Grid mx={4} container columns={1} sx={{ flexGrow: 1 }}>
                                <Grid xs={1}>
                                    <Box>
                                        <Typography mr={1} fontFamily="Raleway" sx={{ color: "white", fontWeight: 700, fontSize: "24px", textAlign: "start" }}>
                                            {t('landing.tailoredServicesTitle')}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography mr={1} fontFamily="Raleway" sx={{ color: "white", fontWeight: 700, fontSize: "16px", textAlign: "start" }}>
                                            {t('landing.tailoredServicesSubtitle')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid xs={1}>
                                    <Box mt={4}>
                                        <Typography mr={1} fontFamily="Raleway" sx={{ color: "white", fontWeight: 500, fontSize: "14px", textAlign: "start" }}>
                                            {t('landing.servicesDescription1')}
                                        </Typography>
                                    </Box>
                                    <Box mt={1}>
                                        <Typography mr={1} fontFamily="Raleway" sx={{ color: "white", fontWeight: 500, fontSize: "14px", textAlign: "start" }}>
                                            {t('landing.servicesDescription2')}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Box ref={carouselRef} display="flex" justifyContent={{ xs: "start" }} sx={{ overflowX: "hidden", width: "100vw", scrollBehavior: "smooth" }}>
                        <CardService
                            imageSrc="/images/services/services_1.png"
                            title={t('landing.uxDesign')}
                            backgroundColor={theme.vars.palette.background.commonWhite}
                        />
                        <CardService
                            imageSrc="/images/services/services_2.png"
                            title={t('landing.uiDesign')}
                            backgroundColor={theme.vars.palette.primary['100']}
                        />
                        <CardService
                            imageSrc="/images/services/services_3.png"
                            title={t('landing.branding')}
                            backgroundColor={theme.vars.palette.background.commonWhite}
                        />
                        <CardService
                            imageSrc="/images/services/services_4.png"
                            title={t('landing.ar')}
                            backgroundColor={theme.vars.palette.primary['100']}
                        />
                        <CardService
                            imageSrc="/images/services/services_5.png"
                            title={t('landing.digitalExperiences')}
                            backgroundColor={theme.vars.palette.background.commonWhite}
                        />
                        <CardService
                            imageSrc="/images/services/services_6.png"
                            title={t('landing.metaTikTokFilters')}
                            backgroundColor={theme.vars.palette.primary['100']}
                        />
                        <CardService
                            imageSrc="/images/services/services_7.png"
                            title={t('landing.productPackaging')}
                            backgroundColor={theme.vars.palette.background.commonWhite}
                        />
                    </Box>
                    <Box sx={{ display: {xs: "flex", lg: "none"}, justifyContent: "end", width: "100vw", mt: 4, backgroundColor: "white" }}>
                        <Box mr={5}>
                            {
                                select === 1 ? (
                                    <Box>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.9998 6L8.70696 11.2929C8.31643 11.6834 8.31643 12.3166 8.70696 12.7071L13.9998 18" stroke="#9FAEFF" strokeWidth="2" strokeLinecap="round" />
                                        </svg>
                                    </Box>
                                ) : (
                                    <Box sx={{ cursor: "pointer" }} onClick={() => move(select - 1)} >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.9998 6L8.70696 11.2929C8.31643 11.6834 8.31643 12.3166 8.70696 12.7071L13.9998 18" stroke="#133BFD" strokeWidth="2" strokeLinecap="round" />
                                        </svg>
                                    </Box>
                                )
                            }
                        </Box>
                        <Box mr={{xs: 8, sm: 8, md: 8}}>
                            {
                                select === 7 ? (
                                    <Box>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 6L15.2929 11.2929C15.6834 11.6834 15.6834 12.3166 15.2929 12.7071L10 18" stroke="#9FAEFF" strokeWidth="2" strokeLinecap="round" />
                                        </svg>
                                    </Box>
                                ) : (
                                    <Box sx={{ cursor: "pointer" }} onClick={() => move(select + 1)} >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 6L15.2929 11.2929C15.6834 11.6834 15.6834 12.3166 15.2929 12.7071L10 18" stroke="#133BFD" strokeWidth="2" strokeLinecap="round" />
                                        </svg>
                                    </Box>
                                )
                            }
                        </Box>
                    </Box>
                    <Box sx={{ display: {xs: "none", lg: "flex"}, justifyContent: "end", width: "100vw", mt: 4, backgroundColor: "white" }}>
                        <Box mr={5}>
                            {
                                select === 1 ? (
                                    <Box>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.9998 6L8.70696 11.2929C8.31643 11.6834 8.31643 12.3166 8.70696 12.7071L13.9998 18" stroke="#9FAEFF" strokeWidth="2" strokeLinecap="round" />
                                        </svg>
                                    </Box>
                                ) : (
                                    <Box sx={{ cursor: "pointer" }} onClick={() => move(select - 1)} >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.9998 6L8.70696 11.2929C8.31643 11.6834 8.31643 12.3166 8.70696 12.7071L13.9998 18" stroke="#133BFD" strokeWidth="2" strokeLinecap="round" />
                                        </svg>
                                    </Box>
                                )
                            }
                        </Box>
                        <Box mr={{xs: 8, sm: 8, md: 8}}>
                            {
                                select === 3 ? (
                                    <Box>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 6L15.2929 11.2929C15.6834 11.6834 15.6834 12.3166 15.2929 12.7071L10 18" stroke="#9FAEFF" strokeWidth="2" strokeLinecap="round" />
                                        </svg>
                                    </Box>
                                ) : (
                                    <Box sx={{ cursor: "pointer" }} onClick={() => move(select + 1)} >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 6L15.2929 11.2929C15.6834 11.6834 15.6834 12.3166 15.2929 12.7071L10 18" stroke="#133BFD" strokeWidth="2" strokeLinecap="round" />
                                        </svg>
                                    </Box>
                                )
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Services;
