import { Box, Typography, useTheme } from "@mui/joy";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

const Business = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const ref = useRef(null);
    const [isFixed, setIsFixed] = useState(false);

    const [componentHeight, setComponentHeight] = useState(0);

    useEffect(() => {
        const handleComponentHeight = () => {
            if (ref.current) {
                const height = ref.current.clientHeight;
                setComponentHeight(height);
            }
        };

        handleComponentHeight(); // Calcula la altura del componente al inicio
    }, []);

    useEffect(() => {
        const handleScrollFixed = () => {
            if (ref.current) {
                const { top } = ref.current.getBoundingClientRect();
                const isInView = top <= 306 && Math.abs(top) <= componentHeight + 200;
                setIsFixed(isInView);
            }
        };

        window.addEventListener('scroll', handleScrollFixed);
        handleScrollFixed(); // Llama al método al inicio para establecer la posición inicial
        return () => {
            window.removeEventListener('scroll', handleScrollFixed);
        };
    }, [componentHeight]);

    return (
        <Box position="relative" ref={ref}>
            <Box m={0} p={0} display="flex" justifyContent="center" alignItems="center" sx={{ width: "100vw", height: "150vh", backgroundColor: "white" }}>
                <Box px={{ xs: 0, md: 0 }}
                    sx={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        transition: "opacity 1s ease",
                        opacity: isFixed ? 1 : 0,
                        pointerEvents: isFixed ? "auto" : "none",
                    }}>
                    <Box>
                        <Typography fontFamily="Raleway" sx={{ color: theme.vars.palette.text.main, fontWeight: 500, fontSize: { xs: "16px", md: "24px" }, textAlign: "center" }}>
                            {t('landing.welcomeMessage')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography fontFamily="Raleway" sx={{ color: theme.vars.palette.text.main, fontWeight: 800, fontSize: { xs: "24px", md: "48px" }, textAlign: "center" }}>
                            {t('landing.businessTitle')}
                        </Typography>
                    </Box>
                    <Box mx={2}>
                        <Typography fontFamily="Raleway" sx={{ color: theme.vars.palette.text.secondary, fontWeight: 500, fontSize: { xs: "14px", md: "20px" }, textAlign: "center", width: { xs: "280px", md: "600px" } }}>
                            {t('landing.businessDescription')}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box display={{ xs: "block", md: "none" }} sx={{ zIndex: -10 }}>
                <Box position="absolute" top={{ xs: "5%", sm: "5%" }} left={{ xs: "10%", sm: "15%" }}>
                    <img alt="" src="/images/fish_mobile.png" />
                </Box>
                <Box position="absolute" top={{ xs: "30%", sm: "30%" }} left={{ xs: "65%", sm: "85%" }}>
                    <img alt="" src="/images/fish_mobile.png" />
                </Box>
                <Box position="absolute" top={{ xs: "55%", sm: "55%" }} left={{ xs: "10%", sm: "10%" }}>
                    <img alt="" src="/images/fish_mobile.png" />
                </Box>
                <Box position="absolute" top={{ xs: "85%", sm: "75%" }} left={{ xs: "65%", sm: "75%" }}>
                    <img alt="" src="/images/fish_mobile.png" />
                </Box>
            </Box>
            <Box display={{ xs: "none", md: "block" }} sx={{ zIndex: -10 }}>
                <Box position="absolute" top={{ md: "10%", lg: "15%", xl: "10%" }} left={{ md: "90%", lg: "80%", xl: "80%" }}>
                    <img alt="" src="/images/fish.png" />
                </Box>
                <Box position="absolute" top={{ md: "20%", lg: "25%", xl: "30%" }} left={{ md: "-20%", lg: "0%", xl: "0%" }}>
                    <img alt="" src="/images/fish.png" />
                </Box>
                <Box position="absolute" top={{ md: "50%", lg: "65%", xl: "60%" }} left={{ md: "70%", lg: "0%", xl: "60%" }}>
                    <img alt="" src="/images/fish.png" />
                </Box>
                <Box position="absolute" top={{ md: "60%", lg: "75%", xl: "70%" }} left={{ md: "0%", lg: "75%", xl: "0%" }}>
                    <img alt="" src="/images/fish.png" />
                </Box>
            </Box>
        </Box>
    )
}

export default Business;
