import { Box } from "@mui/joy";

const Oniric = () => {

    return (
        <>
            <Box display={{ xs: "none", md: "block" }}>
                <svg width="138" height="32" viewBox="0 0 138 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_674_3069)">
                        <path d="M1.63811 5.50808C2.69386 4.11009 4.00049 2.90782 5.90643 2.06203C7.79842 1.22674 9.55453 1.12189 11.004 1.65312C12.4431 2.19135 13.3455 3.31324 13.3316 4.91394C13.3281 8.37396 11.9169 12.3932 11.781 17.0415C11.7253 19.1804 11.6138 20.9524 10.9866 22.1931C10.3699 23.4478 9.16428 24.0419 7.23396 24.1118C5.3106 24.1887 3.69387 23.7588 2.61721 22.8606C1.53358 21.9589 0.972598 20.6798 0.801866 19.0371C0.446463 15.4653 0.164231 12.5679 0.00743567 9.70205C-0.0727042 8.38794 0.599774 6.91306 1.64159 5.50808H1.63811ZM6.51619 20.3652C7.77752 20.2289 8.23745 19.3132 8.23048 17.6287C8.2932 13.0467 9.41516 8.89122 9.19913 5.45216C9.12247 4.20445 8.22003 3.82001 6.76706 4.37571C5.30015 4.95937 4.66252 5.88903 4.74962 7.04937C5.01443 10.2682 4.41513 14.0673 4.49527 18.2717C4.54056 19.806 5.27576 20.5085 6.51967 20.3652H6.51619Z" fill="#F9FBFC" />
                        <path d="M18.4988 24.2342C17.4291 24.1188 16.349 24.021 15.2549 23.9406C11.1678 13.4661 18.8438 6.3329 14.5302 0.0873766C16.1748 -0.0454325 17.7985 -0.0244627 19.3769 0.0978615C23.7253 4.88947 22.7184 8.59764 22.6661 13.2984C23.9588 8.68152 26.3944 5.73875 23.1783 0.569683C24.255 0.747927 25.3107 0.950636 26.3525 1.16033C29.1121 6.08126 28.025 8.22019 26.6348 11.7606C25.2724 15.1822 24.2097 19.2189 26.4954 25.2722C25.2968 25.1009 24.1017 24.9297 22.903 24.7689C18.1574 16.2447 20.3804 10.7575 19.4152 5.75273C18.7462 10.4884 15.3525 16.3076 18.5093 24.2307L18.4988 24.2342Z" fill="#F9FBFC" />
                        <path d="M32.2446 2.38707C35.147 7.90214 34.5094 9.8768 32.9728 13.218C31.5895 16.4194 30.0982 20.0716 32.5791 26.09C31.377 25.9502 30.1888 25.7929 29.0146 25.6322C26.6731 19.5229 27.8857 15.6575 29.2794 12.3443C30.7463 8.9017 31.6662 6.8886 28.8404 1.68807C29.9867 1.93272 31.1191 2.17038 32.2446 2.39056V2.38707Z" fill="#F9FBFC" />
                        <path d="M42.0948 25.6776C41.8544 25.3246 41.5234 24.6675 41.3561 23.7169C41.1227 22.3748 41.2342 21.0957 41.4816 19.9563C41.7046 18.9602 41.7568 18.2787 41.5129 17.8069C41.2725 17.3386 40.7324 17.01 39.8753 16.8073C39.4467 16.706 39.0181 16.6046 38.593 16.4998C37.6139 18.9533 36.5303 22.7663 38.9693 26.6457C37.7045 26.5793 36.4571 26.4849 35.2306 26.3731C32.6 20.554 34.3247 17.0275 35.6627 13.8785C37.2167 10.5863 37.6314 8.53124 34.6975 2.83443C36.3874 3.11752 38.0739 3.3412 39.7673 3.49148C41.5025 3.63478 43.0704 4.36523 44.3561 5.61643C45.6244 6.86064 46.7638 8.6256 47.1366 10.5688C47.2586 11.2224 47.3213 11.841 47.3318 12.4316C47.3318 14.7418 45.9868 15.8462 44.1819 16.1223C44.938 16.5907 45.3805 17.1953 45.5129 17.9537C45.6488 18.7156 45.5199 19.6278 45.2795 20.7741C45.0635 21.8401 44.9763 22.9969 45.2412 24.1538C45.3875 24.7934 45.6488 25.3036 45.9311 25.6671C46.2133 26.0341 46.6279 26.3731 47.1924 26.6597C45.8439 26.7226 44.5025 26.754 43.1749 26.754C42.6837 26.401 42.3387 26.0306 42.0983 25.6741L42.0948 25.6776ZM39.7394 13.8855C40.2167 14.0009 40.6906 14.1127 41.1645 14.2211C41.8927 14.3888 42.4955 14.3644 42.9519 14.1372C43.4049 13.91 43.7359 13.4347 43.83 12.6623C43.9101 11.9948 43.9101 11.2923 43.8091 10.5339C43.6976 9.69856 43.3701 9.01354 42.9729 8.56269C42.5722 8.10834 42.06 7.83224 41.4676 7.74836C40.9275 7.67147 40.384 7.5841 39.8369 7.49323C40.7498 10.1389 40.5059 12.0612 39.7394 13.8855Z" fill="#F9FBFC" />
                        <path d="M50.5374 3.65924C52.6907 9.64264 53.5339 13.5186 52.6977 17.1498C52.2238 20.6588 50.3875 23.8882 53.3701 26.1774C52.0321 26.3207 50.6942 26.443 49.3631 26.5339C46.1401 23.3849 48.3213 20.1136 49.06 16.7654C50.1959 13.2669 49.5374 9.86282 47.067 3.75011C48.2168 3.73613 49.3736 3.70118 50.5339 3.65575L50.5374 3.65924Z" fill="#F9FBFC" />
                        <path d="M67.5688 20.8965C67.8162 22.4168 67.2761 23.3954 66.3005 24.0245C65.3075 24.6571 64.0078 24.9541 62.088 25.2023C60.1855 25.4364 58.3527 25.5238 56.8092 25.1359C55.2726 24.7514 54.3318 23.8218 54.398 22.3818C54.5862 19.3202 55.8161 15.5736 54.844 10.8869C54.4329 8.70948 54.1715 6.78724 54.6384 5.31586C55.0879 3.83748 56.2412 2.9917 57.9486 3.01966C59.6524 3.04412 61.1123 3.77457 62.2935 4.67977C63.4712 5.60944 64.3075 6.79074 64.8719 8.7619C65.0949 9.54827 65.3179 10.3556 65.5305 11.1664C64.3737 11.5998 63.2448 11.9668 62.1367 12.2674C61.9799 11.3412 61.7918 10.4045 61.5792 9.46789C61.182 7.75535 60.3388 7.01092 59.175 7.19266C57.9973 7.3709 57.7221 8.40891 58.1053 10.2018C59.1925 15.1018 58.5374 19.4705 58.6385 22.4238C58.7081 23.4897 59.6489 23.8253 61.1611 23.6575C62.6873 23.4688 63.3423 22.9725 63.196 21.903C63.0914 21.1097 63.0183 20.2045 62.9381 19.2119C64.2761 18.9498 65.6524 18.6107 67.1054 18.1809C67.2866 19.1595 67.4434 20.0751 67.5758 20.9L67.5688 20.8965Z" fill="#F9FBFC" />
                        <path d="M67.2761 29.896C65.966 30.1162 64.6629 30.3888 63.4294 30.7208C63.1995 29.7003 62.8859 28.7462 62.5444 27.827C63.7918 27.4146 65.081 27.0581 66.3702 26.7471C66.6385 27.7711 66.9695 28.8056 67.2727 29.896H67.2761Z" fill="#F9FBFC" />
                        <path d="M67.1821 9.57624C67.7012 8.36697 68.7082 7.34994 70.635 6.66143C72.541 5.97991 74.5549 6.00088 76.2135 6.73482C77.872 7.46877 78.9382 8.8388 79.1543 10.6212C79.1856 10.8519 79.21 11.0826 79.2309 11.3167C78.0253 11.3971 76.8093 11.5439 75.5828 11.7711C75.534 11.474 75.4783 11.1769 75.4121 10.8834C75.2483 10.1529 74.8999 9.66012 74.4295 9.41547C73.9591 9.17082 73.3737 9.13587 72.7047 9.32809C72.0357 9.52032 71.6002 9.83836 71.3842 10.2578C71.1647 10.6806 71.189 11.2259 71.3877 11.9144C71.6316 12.7602 72.0671 13.4277 72.7256 13.945C73.3842 14.4622 74.2901 15.0389 75.3842 15.7449C76.2936 16.3565 76.9835 16.9576 77.5062 17.5028C78.0288 18.0481 78.4644 18.7436 78.8441 19.5998C79.2239 20.4561 79.4365 21.4731 79.5515 22.6614C79.7431 24.6815 79.7152 26.3416 79.1194 27.5194C78.5271 28.7077 77.3598 29.3019 75.4783 29.3613C73.6002 29.4312 71.9138 29.0083 70.5932 28.1695C69.2761 27.3237 68.3842 26.118 68.1403 24.4019C68.081 23.9685 68.0322 23.5317 67.9904 23.0983C69.3214 22.7488 70.6106 22.4517 71.858 22.2001C71.8755 22.7418 71.9068 23.287 71.9626 23.8322C72.1159 25.3106 73.0079 25.9537 74.325 25.8139C75.6595 25.6741 76.0776 24.8248 75.9277 23.2486C75.8337 22.235 75.5549 21.3893 75.0149 20.7322C74.4713 20.0751 73.6804 19.3796 72.5584 18.7331C71.6246 18.1739 70.8127 17.73 70.1612 17.3561C69.5096 16.9821 68.9068 16.4998 68.3319 15.9161C67.7535 15.3325 67.3528 14.602 67.0949 13.6933C66.6524 12.159 66.6699 10.7995 67.1821 9.57973V9.57624Z" fill="#F9FBFC" />
                        <path d="M90.2101 5.60944C90.9522 7.07383 91.3181 8.39144 91.5341 9.55177C90.3007 9.30363 89.0428 9.06946 87.7536 8.87375C88.454 11.9423 87.3703 14.983 87.3634 18.0795C87.1857 21.246 88.0811 24.4718 88.7745 29.5186C87.7083 29.4032 86.6212 29.3019 85.5097 29.2215C84.555 24.5732 83.8337 21.239 83.9348 17.9537C83.9139 14.7208 84.7153 11.5404 83.9696 8.44386C82.6212 8.3495 81.2449 8.32504 79.8476 8.39493C79.5201 7.32547 78.9905 6.23853 78.0532 5.17606C82.3285 4.64133 86.4609 5.00131 90.2101 5.60944Z" fill="#F9FBFC" />
                        <path d="M95.8129 23.3429C95.9662 25.0345 96.6108 26.1459 97.7084 26.2787C98.8094 26.443 99.3146 25.4469 99.2554 23.7554C98.8338 16.8947 102.106 15.6924 98.7223 7.13674C99.799 7.31848 100.876 7.48624 101.959 7.62953C105.287 16.1678 102.573 17.426 102.402 23.9546C102.374 25.9991 101.973 27.9948 101.262 29.3858C100.555 30.7872 99.5551 31.4688 98.008 31.2451C96.4645 31.0354 95.224 29.9904 94.3042 28.3967C93.3913 26.81 92.7014 24.8563 92.4505 22.7837C91.231 15.9895 95.0882 13.8611 91.6282 5.8436C92.8268 6.0533 93.9976 6.27348 95.1578 6.49017C98.5586 14.8816 94.8791 16.3949 95.8129 23.3394V23.3429Z" fill="#F9FBFC" />
                        <path d="M109.945 8.29008C111.775 8.33902 113.252 9.12888 114.458 10.4395C115.653 11.7431 116.649 13.4766 116.9 15.3954C117.461 19.4286 115.987 22.1442 115.343 25.9921C115.026 27.8445 114.593 29.4067 113.949 30.4447C113.297 31.4862 112.304 32.035 110.506 31.993C108.618 31.9371 106.778 31.8148 104.998 31.6435C104.186 25.1568 104.844 22.1057 105.538 18.9917C106.555 15.7449 106.078 13.9065 104.315 7.90564C106.147 8.09437 108.019 8.22019 109.949 8.28659L109.945 8.29008ZM107.907 27.5055C108.534 27.6068 109.172 27.7082 109.809 27.8025C110.367 27.8864 110.803 27.7361 111.13 27.3727C111.458 27.0127 111.66 26.3871 111.775 25.5623C112.294 21.4102 113.998 18.9952 113.308 15.0214C113.179 14.242 112.862 13.6199 112.447 13.2215C112.033 12.8196 111.524 12.5994 110.945 12.5644C110.28 12.5225 109.618 12.4771 108.959 12.4211C110.977 18.4884 107.816 20.4456 107.907 27.502V27.5055Z" fill="#F9FBFC" />
                        <path d="M122.151 8.17126C122.97 13.8226 123.196 17.7021 122.451 21.3753C121.893 24.9856 120.371 28.3932 122.036 31.6086C120.796 31.7169 119.552 31.8078 118.308 31.8777C116.506 27.806 118.262 24.4998 118.848 21.0642C119.646 17.5832 119.269 13.9764 118.078 8.29008C119.409 8.25863 120.768 8.2132 122.148 8.16776L122.151 8.17126Z" fill="#F9FBFC" />
                        <path d="M126.548 9.80341C127.576 8.36697 129.263 7.55964 131.395 7.60507C133.527 7.64701 135.263 8.35998 136.419 9.3211C137.569 10.2997 138.029 11.5789 137.841 13.6129C137.399 18.0411 136.9 22.7523 136.454 26.2298C136.238 27.855 135.423 28.9524 134.304 29.6863C133.172 30.4238 131.883 30.7733 130.113 30.9585C128.357 31.1298 126.743 31.0599 125.479 30.4377C124.217 29.8261 123.601 28.6308 123.841 26.9987C124.395 23.4653 125.405 19.7326 125.304 15.1752C125.263 13.0817 125.51 11.2364 126.548 9.8069V9.80341ZM129.757 28.858C131.158 28.7427 131.893 28.1625 132.019 26.9148C132.336 23.5002 133.05 18.8729 133.52 14.0883C133.67 12.3547 132.921 11.5719 131.437 11.7082C129.945 11.841 129.283 12.844 129.231 14.609C129.026 19.4391 128.165 23.7099 127.757 27.1909C127.625 28.4596 128.364 28.9629 129.757 28.858Z" fill="#F9FBFC" />
                    </g>
                    <defs>
                        <clipPath id="clip0_674_3069">
                            <rect width="137.882" height="32" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </Box>
            <Box display={{ xs: "none", sm: "block", md: "none" }}>
                <svg width="138" height="32" viewBox="0 0 138 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_726_2058)">
                        <path d="M1.63811 5.50808C2.69386 4.11009 4.00049 2.90782 5.90643 2.06203C7.79842 1.22674 9.55453 1.12189 11.004 1.65312C12.4431 2.19135 13.3455 3.31324 13.3316 4.91394C13.3281 8.37396 11.9169 12.3932 11.781 17.0415C11.7253 19.1804 11.6138 20.9524 10.9866 22.1931C10.3699 23.4478 9.16428 24.0419 7.23396 24.1118C5.3106 24.1887 3.69387 23.7588 2.61721 22.8606C1.53358 21.9589 0.972598 20.6798 0.801866 19.0371C0.446463 15.4653 0.164231 12.5679 0.00743567 9.70205C-0.0727042 8.38794 0.599774 6.91306 1.64159 5.50808H1.63811ZM6.51619 20.3652C7.77752 20.2289 8.23745 19.3132 8.23048 17.6287C8.2932 13.0467 9.41516 8.89122 9.19913 5.45216C9.12247 4.20445 8.22003 3.82001 6.76706 4.37571C5.30015 4.95937 4.66252 5.88903 4.74962 7.04937C5.01443 10.2682 4.41513 14.0673 4.49527 18.2717C4.54056 19.806 5.27576 20.5085 6.51967 20.3652H6.51619Z" fill="#F9FBFC" />
                        <path d="M18.4988 24.2342C17.4291 24.1188 16.349 24.021 15.2549 23.9406C11.1678 13.4661 18.8438 6.3329 14.5302 0.0873766C16.1748 -0.0454325 17.7985 -0.0244627 19.3769 0.0978615C23.7253 4.88947 22.7184 8.59764 22.6661 13.2984C23.9588 8.68152 26.3944 5.73875 23.1783 0.569683C24.255 0.747927 25.3107 0.950636 26.3525 1.16033C29.1121 6.08126 28.025 8.22019 26.6348 11.7606C25.2724 15.1822 24.2097 19.2189 26.4954 25.2722C25.2968 25.1009 24.1017 24.9297 22.903 24.7689C18.1574 16.2447 20.3804 10.7575 19.4152 5.75273C18.7462 10.4884 15.3525 16.3076 18.5093 24.2307L18.4988 24.2342Z" fill="#F9FBFC" />
                        <path d="M32.2446 2.38707C35.147 7.90214 34.5094 9.8768 32.9728 13.218C31.5895 16.4194 30.0982 20.0716 32.5791 26.09C31.377 25.9502 30.1888 25.7929 29.0146 25.6322C26.6731 19.5229 27.8857 15.6575 29.2794 12.3443C30.7463 8.9017 31.6662 6.8886 28.8404 1.68807C29.9867 1.93272 31.1191 2.17038 32.2446 2.39056V2.38707Z" fill="#F9FBFC" />
                        <path d="M42.0948 25.6776C41.8544 25.3246 41.5234 24.6675 41.3561 23.7169C41.1227 22.3748 41.2342 21.0957 41.4816 19.9563C41.7046 18.9602 41.7568 18.2787 41.5129 17.8069C41.2725 17.3386 40.7324 17.01 39.8753 16.8073C39.4467 16.706 39.0181 16.6046 38.593 16.4998C37.6139 18.9533 36.5303 22.7663 38.9693 26.6457C37.7045 26.5793 36.4571 26.4849 35.2306 26.3731C32.6 20.554 34.3247 17.0275 35.6627 13.8785C37.2167 10.5863 37.6314 8.53124 34.6975 2.83443C36.3874 3.11752 38.0739 3.3412 39.7673 3.49148C41.5025 3.63478 43.0704 4.36523 44.3561 5.61643C45.6244 6.86064 46.7638 8.6256 47.1366 10.5688C47.2586 11.2224 47.3213 11.841 47.3318 12.4316C47.3318 14.7418 45.9868 15.8462 44.1819 16.1223C44.938 16.5907 45.3805 17.1953 45.5129 17.9537C45.6488 18.7156 45.5199 19.6278 45.2795 20.7741C45.0635 21.8401 44.9763 22.9969 45.2412 24.1538C45.3875 24.7934 45.6488 25.3036 45.9311 25.6671C46.2133 26.0341 46.6279 26.3731 47.1924 26.6597C45.8439 26.7226 44.5025 26.754 43.1749 26.754C42.6837 26.401 42.3387 26.0306 42.0983 25.6741L42.0948 25.6776ZM39.7394 13.8855C40.2167 14.0009 40.6906 14.1127 41.1645 14.2211C41.8927 14.3888 42.4955 14.3644 42.9519 14.1372C43.4049 13.91 43.7359 13.4347 43.83 12.6623C43.9101 11.9948 43.9101 11.2923 43.8091 10.5339C43.6976 9.69856 43.3701 9.01354 42.9729 8.56269C42.5722 8.10834 42.06 7.83224 41.4676 7.74836C40.9275 7.67147 40.384 7.5841 39.8369 7.49323C40.7498 10.1389 40.5059 12.0612 39.7394 13.8855Z" fill="#F9FBFC" />
                        <path d="M50.5374 3.65924C52.6907 9.64264 53.5339 13.5186 52.6977 17.1498C52.2238 20.6588 50.3875 23.8882 53.3701 26.1774C52.0321 26.3207 50.6942 26.443 49.3631 26.5339C46.1401 23.3849 48.3213 20.1136 49.06 16.7654C50.1959 13.2669 49.5374 9.86282 47.067 3.75011C48.2168 3.73613 49.3736 3.70118 50.5339 3.65575L50.5374 3.65924Z" fill="#F9FBFC" />
                        <path d="M67.5688 20.8965C67.8162 22.4168 67.2761 23.3954 66.3005 24.0245C65.3075 24.6571 64.0078 24.9541 62.088 25.2023C60.1855 25.4364 58.3527 25.5238 56.8092 25.1359C55.2726 24.7514 54.3318 23.8218 54.398 22.3818C54.5862 19.3202 55.8161 15.5736 54.844 10.8869C54.4329 8.70948 54.1715 6.78724 54.6384 5.31586C55.0879 3.83748 56.2412 2.9917 57.9486 3.01966C59.6524 3.04412 61.1123 3.77457 62.2935 4.67977C63.4712 5.60944 64.3075 6.79074 64.8719 8.7619C65.0949 9.54827 65.3179 10.3556 65.5305 11.1664C64.3737 11.5998 63.2448 11.9668 62.1367 12.2674C61.9799 11.3412 61.7918 10.4045 61.5792 9.46789C61.182 7.75535 60.3388 7.01092 59.175 7.19266C57.9973 7.3709 57.7221 8.40891 58.1053 10.2018C59.1925 15.1018 58.5374 19.4705 58.6385 22.4238C58.7081 23.4897 59.6489 23.8253 61.1611 23.6575C62.6873 23.4688 63.3423 22.9725 63.196 21.903C63.0914 21.1097 63.0183 20.2045 62.9381 19.2119C64.2761 18.9498 65.6524 18.6107 67.1054 18.1809C67.2866 19.1595 67.4434 20.0751 67.5758 20.9L67.5688 20.8965Z" fill="#F9FBFC" />
                        <path d="M67.2761 29.896C65.966 30.1162 64.6629 30.3888 63.4294 30.7208C63.1995 29.7003 62.8859 28.7462 62.5444 27.827C63.7918 27.4146 65.081 27.0581 66.3702 26.7471C66.6385 27.7711 66.9695 28.8056 67.2727 29.896H67.2761Z" fill="#F9FBFC" />
                        <path d="M67.1821 9.57624C67.7012 8.36697 68.7082 7.34994 70.635 6.66143C72.541 5.97991 74.5549 6.00088 76.2135 6.73482C77.872 7.46877 78.9382 8.8388 79.1543 10.6212C79.1856 10.8519 79.21 11.0826 79.2309 11.3167C78.0253 11.3971 76.8093 11.5439 75.5828 11.7711C75.534 11.474 75.4783 11.1769 75.4121 10.8834C75.2483 10.1529 74.8999 9.66012 74.4295 9.41547C73.9591 9.17082 73.3737 9.13587 72.7047 9.32809C72.0357 9.52032 71.6002 9.83836 71.3842 10.2578C71.1647 10.6806 71.189 11.2259 71.3877 11.9144C71.6316 12.7602 72.0671 13.4277 72.7256 13.945C73.3842 14.4622 74.2901 15.0389 75.3842 15.7449C76.2936 16.3565 76.9835 16.9576 77.5062 17.5028C78.0288 18.0481 78.4644 18.7436 78.8441 19.5998C79.2239 20.4561 79.4365 21.4731 79.5515 22.6614C79.7431 24.6815 79.7152 26.3416 79.1194 27.5194C78.5271 28.7077 77.3598 29.3019 75.4783 29.3613C73.6002 29.4312 71.9138 29.0083 70.5932 28.1695C69.2761 27.3237 68.3842 26.118 68.1403 24.4019C68.081 23.9685 68.0322 23.5317 67.9904 23.0983C69.3214 22.7488 70.6106 22.4517 71.858 22.2001C71.8755 22.7418 71.9068 23.287 71.9626 23.8322C72.1159 25.3106 73.0079 25.9537 74.325 25.8139C75.6595 25.6741 76.0776 24.8248 75.9277 23.2486C75.8337 22.235 75.5549 21.3893 75.0149 20.7322C74.4713 20.0751 73.6804 19.3796 72.5584 18.7331C71.6246 18.1739 70.8127 17.73 70.1612 17.3561C69.5096 16.9821 68.9068 16.4998 68.3319 15.9161C67.7535 15.3325 67.3528 14.602 67.0949 13.6933C66.6524 12.159 66.6699 10.7995 67.1821 9.57973V9.57624Z" fill="#F9FBFC" />
                        <path d="M90.2101 5.60944C90.9522 7.07383 91.3181 8.39144 91.5341 9.55177C90.3007 9.30363 89.0428 9.06946 87.7536 8.87375C88.454 11.9423 87.3703 14.983 87.3634 18.0795C87.1857 21.246 88.0811 24.4718 88.7745 29.5186C87.7083 29.4032 86.6212 29.3019 85.5097 29.2215C84.555 24.5732 83.8337 21.239 83.9348 17.9537C83.9139 14.7208 84.7153 11.5404 83.9696 8.44386C82.6212 8.3495 81.2449 8.32504 79.8476 8.39493C79.5201 7.32547 78.9905 6.23853 78.0532 5.17606C82.3285 4.64133 86.4609 5.00131 90.2101 5.60944Z" fill="#F9FBFC" />
                        <path d="M95.8129 23.3429C95.9662 25.0345 96.6108 26.1459 97.7084 26.2787C98.8094 26.443 99.3146 25.4469 99.2554 23.7554C98.8338 16.8947 102.106 15.6924 98.7223 7.13674C99.799 7.31848 100.876 7.48624 101.959 7.62953C105.287 16.1678 102.573 17.426 102.402 23.9546C102.374 25.9991 101.973 27.9948 101.262 29.3858C100.555 30.7872 99.5551 31.4688 98.008 31.2451C96.4645 31.0354 95.224 29.9904 94.3042 28.3967C93.3913 26.81 92.7014 24.8563 92.4505 22.7837C91.231 15.9895 95.0882 13.8611 91.6282 5.8436C92.8268 6.0533 93.9976 6.27348 95.1578 6.49017C98.5586 14.8816 94.8791 16.3949 95.8129 23.3394V23.3429Z" fill="#F9FBFC" />
                        <path d="M109.945 8.29008C111.775 8.33902 113.252 9.12888 114.458 10.4395C115.653 11.7431 116.649 13.4766 116.9 15.3954C117.461 19.4286 115.987 22.1442 115.343 25.9921C115.026 27.8445 114.593 29.4067 113.949 30.4447C113.297 31.4862 112.304 32.035 110.506 31.993C108.618 31.9371 106.778 31.8148 104.998 31.6435C104.186 25.1568 104.844 22.1057 105.538 18.9917C106.555 15.7449 106.078 13.9065 104.315 7.90564C106.147 8.09437 108.019 8.22019 109.949 8.28659L109.945 8.29008ZM107.907 27.5055C108.534 27.6068 109.172 27.7082 109.809 27.8025C110.367 27.8864 110.803 27.7361 111.13 27.3727C111.458 27.0127 111.66 26.3871 111.775 25.5623C112.294 21.4102 113.998 18.9952 113.308 15.0214C113.179 14.242 112.862 13.6199 112.447 13.2215C112.033 12.8196 111.524 12.5994 110.945 12.5644C110.28 12.5225 109.618 12.4771 108.959 12.4211C110.977 18.4884 107.816 20.4456 107.907 27.502V27.5055Z" fill="#F9FBFC" />
                        <path d="M122.151 8.17126C122.97 13.8226 123.196 17.7021 122.451 21.3753C121.893 24.9856 120.371 28.3932 122.036 31.6086C120.796 31.7169 119.552 31.8078 118.308 31.8777C116.506 27.806 118.262 24.4998 118.848 21.0642C119.646 17.5832 119.269 13.9764 118.078 8.29008C119.409 8.25863 120.768 8.2132 122.148 8.16776L122.151 8.17126Z" fill="#F9FBFC" />
                        <path d="M126.548 9.80341C127.576 8.36697 129.263 7.55964 131.395 7.60507C133.527 7.64701 135.263 8.35998 136.419 9.3211C137.569 10.2997 138.029 11.5789 137.841 13.6129C137.399 18.0411 136.9 22.7523 136.454 26.2298C136.238 27.855 135.423 28.9524 134.304 29.6863C133.172 30.4238 131.883 30.7733 130.113 30.9585C128.357 31.1298 126.743 31.0599 125.479 30.4377C124.217 29.8261 123.601 28.6308 123.841 26.9987C124.395 23.4653 125.405 19.7326 125.304 15.1752C125.263 13.0817 125.51 11.2364 126.548 9.8069V9.80341ZM129.757 28.858C131.158 28.7427 131.893 28.1625 132.019 26.9148C132.336 23.5002 133.05 18.8729 133.52 14.0883C133.67 12.3547 132.921 11.5719 131.437 11.7082C129.945 11.841 129.283 12.844 129.231 14.609C129.026 19.4391 128.165 23.7099 127.757 27.1909C127.625 28.4596 128.364 28.9629 129.757 28.858Z" fill="#F9FBFC" />
                    </g>
                    <defs>
                        <clipPath id="clip0_726_2058">
                            <rect width="137.882" height="32" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

            </Box>
            <Box display={{ xs: "block", sm: "none" }}>
                <svg width="104" height="24" viewBox="0 0 104 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_751_3905)">
                        <path d="M1.22785 4.13118C2.01966 3.08269 2.99964 2.18099 4.42909 1.54665C5.84808 0.920174 7.16517 0.841537 8.25228 1.23996C9.33155 1.64363 10.0084 2.48505 9.99794 3.68557C9.99532 6.28059 8.93695 9.29501 8.83504 12.7812C8.79323 14.3854 8.7096 15.7144 8.23921 16.6449C7.77667 17.586 6.87248 18.0316 5.42474 18.084C3.98222 18.1417 2.76967 17.8193 1.96217 17.1456C1.14945 16.4693 0.728716 15.51 0.600667 14.278C0.334115 11.5991 0.122441 9.42607 0.00484433 7.27666C-0.0552606 6.29108 0.449098 5.18492 1.23046 4.13118H1.22785ZM4.88641 15.274C5.83241 15.1718 6.17735 14.485 6.17213 13.2216C6.21917 9.78518 7.06064 6.66854 6.89861 4.08924C6.84112 3.15346 6.16429 2.86513 5.07456 3.2819C3.97438 3.71965 3.49615 4.4169 3.56149 5.28715C3.76009 7.7013 3.31061 10.5506 3.37072 13.7039C3.40469 14.8546 3.95609 15.3815 4.88902 15.274H4.88641Z" fill="#F9FBFC" />
                        <path d="M13.8734 18.1757C13.0711 18.0892 12.261 18.0159 11.4405 17.9556C8.3751 10.0997 14.1321 4.7498 10.8969 0.0656545C12.1304 -0.0339523 13.3481 -0.0182249 14.5319 0.0735182C17.7933 3.66723 17.038 6.44835 16.9988 9.97391C17.9684 6.51126 19.795 4.30419 17.383 0.427385C18.1905 0.561067 18.9823 0.713099 19.7637 0.870373C21.8334 4.56107 21.018 6.16526 19.9753 8.82057C18.9536 11.3868 18.1565 14.4143 19.8708 18.9543C18.9719 18.8258 18.0755 18.6974 17.1765 18.5768C13.6173 12.1836 15.2846 8.06828 14.5607 4.31467C14.0589 7.86644 11.5136 12.2308 13.8812 18.1731L13.8734 18.1757Z" fill="#F9FBFC" />
                        <path d="M24.1827 1.79042C26.3595 5.92673 25.8813 7.40772 24.7289 9.91362C23.6914 12.3147 22.5729 15.0539 24.4336 19.5676C23.532 19.4628 22.6409 19.3448 21.7602 19.2242C20.0041 14.6423 20.9135 11.7432 21.9588 9.25831C23.059 6.6764 23.7489 5.16657 21.6295 1.26618C22.4893 1.44966 23.3386 1.62791 24.1827 1.79305V1.79042Z" fill="#F9FBFC" />
                        <path d="M31.5704 19.2583C31.39 18.9936 31.1418 18.5008 31.0164 17.7878C30.8413 16.7812 30.9249 15.8219 31.1104 14.9674C31.2777 14.2203 31.3169 13.7092 31.134 13.3553C30.9536 13.0041 30.5486 12.7577 29.9057 12.6056C29.5843 12.5296 29.2629 12.4536 28.944 12.375C28.2097 14.2151 27.397 17.0748 29.2263 19.9844C28.2777 19.9346 27.3421 19.8638 26.4223 19.7799C24.4492 15.4156 25.7428 12.7708 26.7463 10.409C27.9118 7.93983 28.2228 6.39855 26.0224 2.12594C27.2899 2.33826 28.5547 2.50602 29.8247 2.61873C31.1261 2.7262 32.3021 3.27404 33.2664 4.21244C34.2176 5.1456 35.0721 6.46932 35.3517 7.92673C35.4432 8.4169 35.4902 8.88086 35.4981 9.32384C35.4981 11.0565 34.4894 11.8848 33.1357 12.0919C33.7028 12.4431 34.0347 12.8966 34.134 13.4654C34.2359 14.0368 34.1392 14.721 33.9589 15.5807C33.7969 16.3802 33.7315 17.2478 33.9301 18.1155C34.0399 18.5951 34.2359 18.9778 34.4476 19.2505C34.6592 19.5257 34.9702 19.7799 35.3936 19.9949C34.3822 20.0421 33.3761 20.0657 32.3805 20.0657C32.012 19.8009 31.7533 19.5231 31.573 19.2557L31.5704 19.2583ZM29.8038 10.4143C30.1618 10.5008 30.5172 10.5847 30.8726 10.6659C31.4188 10.7917 31.8709 10.7734 32.2132 10.603C32.5529 10.4326 32.8012 10.0761 32.8718 9.49685C32.9319 8.99619 32.9319 8.46932 32.8561 7.90052C32.7725 7.27404 32.5268 6.76028 32.2289 6.42214C31.9284 6.08138 31.5442 5.8743 31.1 5.81139C30.6949 5.75373 30.2873 5.6882 29.877 5.62004C30.5616 7.60432 30.3787 9.04599 29.8038 10.4143Z" fill="#F9FBFC" />
                        <path d="M37.9023 2.74455C39.5173 7.2321 40.1497 10.139 39.5225 12.8625C39.1671 15.4942 37.7899 17.9162 40.0269 19.6332C39.0234 19.7406 38.0199 19.8324 37.0216 19.9005C34.6044 17.5388 36.2403 15.0853 36.7943 12.5742C37.6462 9.95032 37.1523 7.39724 35.2995 2.81271C36.1619 2.80222 37.0295 2.77601 37.8997 2.74193L37.9023 2.74455Z" fill="#F9FBFC" />
                        <path d="M50.6759 15.6725C50.8614 16.8127 50.4564 17.5466 49.7247 18.0185C48.9799 18.4929 48.0051 18.7157 46.5652 18.9018C45.1384 19.0774 43.7638 19.143 42.6061 18.852C41.4537 18.5637 40.7481 17.8664 40.7978 16.7865C40.9389 14.4903 41.8614 11.6803 41.1323 8.16526C40.8239 6.53223 40.6279 5.09055 40.9781 3.98702C41.3152 2.87823 42.1802 2.2439 43.4607 2.26487C44.7386 2.28322 45.8335 2.83105 46.7194 3.50995C47.6027 4.2072 48.2299 5.09318 48.6532 6.57155C48.8205 7.16133 48.9877 7.76683 49.1471 8.37496C48.2795 8.69999 47.4328 8.97522 46.6018 9.20065C46.4842 8.50602 46.3431 7.80353 46.1837 7.10104C45.8858 5.81664 45.2534 5.25831 44.3805 5.39462C43.4973 5.5283 43.2908 6.30681 43.5783 7.6515C44.3936 11.3265 43.9023 14.603 43.9781 16.8179C44.0304 17.6174 44.736 17.8691 45.8701 17.7432C47.0147 17.6017 47.506 17.2295 47.3962 16.4274C47.3178 15.8324 47.263 15.1535 47.2029 14.409C48.2064 14.2124 49.2386 13.9582 50.3283 13.6358C50.4642 14.3697 50.5818 15.0565 50.6811 15.6751L50.6759 15.6725Z" fill="#F9FBFC" />
                        <path d="M50.4564 22.4221C49.4738 22.5873 48.4964 22.7917 47.5713 23.0408C47.3989 22.2754 47.1637 21.5598 46.9076 20.8704C47.8431 20.5611 48.81 20.2937 49.7769 20.0604C49.9781 20.8284 50.2264 21.6043 50.4538 22.4221H50.4564Z" fill="#F9FBFC" />
                        <path d="M50.3858 7.1823C50.7752 6.27535 51.5304 5.51258 52.9755 4.99619C54.405 4.48505 55.9155 4.50078 57.1594 5.05124C58.4033 5.6017 59.2029 6.62922 59.365 7.96605C59.3885 8.13905 59.4068 8.31205 59.4224 8.48767C58.5183 8.54796 57.6062 8.65805 56.6864 8.82843C56.6498 8.60563 56.608 8.38282 56.5583 8.16264C56.4355 7.6148 56.1742 7.24521 55.8214 7.06172C55.4686 6.87824 55.0296 6.85202 54.5278 6.99619C54.0261 7.14036 53.6994 7.37889 53.5374 7.69344C53.3728 8.01061 53.3911 8.41952 53.54 8.9359C53.7229 9.57024 54.0496 10.0709 54.5435 10.4588C55.0374 10.8468 55.7169 11.2793 56.5374 11.8088C57.2195 12.2675 57.7369 12.7183 58.1289 13.1273C58.5209 13.5362 58.8475 14.0578 59.1324 14.7C59.4172 15.3422 59.5766 16.105 59.6629 16.9962C59.8066 18.5113 59.7857 19.7563 59.3388 20.6397C58.8946 21.5309 58.0191 21.9765 56.608 22.0211C55.1994 22.0735 53.9346 21.7563 52.9442 21.1273C51.9564 20.4929 51.2874 19.5886 51.1045 18.3016C51.06 17.9765 51.0234 17.6489 50.9921 17.3238C51.9903 17.0617 52.9572 16.8389 53.8928 16.6502C53.9059 17.0565 53.9294 17.4654 53.9712 17.8743C54.0862 18.9831 54.7552 19.4654 55.743 19.3605C56.7439 19.2557 57.0574 18.6187 56.9451 17.4366C56.8745 16.6764 56.6655 16.0421 56.2604 15.5493C55.8527 15.0565 55.2595 14.5349 54.4181 14.0499C53.7177 13.6305 53.1088 13.2976 52.6201 13.0172C52.1315 12.7367 51.6794 12.375 51.2482 11.9372C50.8144 11.4995 50.5139 10.9516 50.3205 10.2701C49.9886 9.11939 50.0017 8.09973 50.3858 7.18492V7.1823Z" fill="#F9FBFC" />
                        <path d="M67.6568 4.2072C68.2134 5.3055 68.4878 6.2937 68.6499 7.16395C67.7248 6.97784 66.7814 6.80222 65.8145 6.65543C66.3397 8.95687 65.527 11.2373 65.5218 13.5598C65.3885 15.9346 66.0601 18.354 66.5802 22.139C65.7805 22.0525 64.9652 21.9765 64.1315 21.9162C63.4155 18.43 62.8746 15.9294 62.9503 13.4654C62.9347 11.0408 63.5357 8.65543 62.9765 6.33302C61.9651 6.26225 60.9329 6.2439 59.885 6.29632C59.6393 5.49423 59.2421 4.67902 58.5392 3.88217C61.7456 3.48112 64.8449 3.75111 67.6568 4.2072Z" fill="#F9FBFC" />
                        <path d="M71.8589 17.5073C71.9739 18.776 72.4574 19.6096 73.2805 19.7092C74.1063 19.8324 74.4852 19.0853 74.4408 17.8166C74.1246 12.6712 76.5785 11.7695 74.041 5.35268C74.8485 5.48898 75.656 5.6148 76.4687 5.72227C78.9644 12.1259 76.9286 13.0696 76.8006 17.966C76.7797 19.4995 76.4792 20.9962 75.9461 22.0394C75.4156 23.0906 74.6656 23.6017 73.5053 23.4339C72.3476 23.2767 71.4173 22.4929 70.7274 21.2976C70.0427 20.1076 69.5253 18.6423 69.3371 17.0879C68.4225 11.9923 71.3154 10.3959 68.7204 4.38282C69.6194 4.5401 70.4974 4.70524 71.3676 4.86775C73.9182 11.1613 71.1586 12.2963 71.8589 17.5047V17.5073Z" fill="#F9FBFC" />
                        <path d="M82.4583 6.21769C83.8303 6.25438 84.9383 6.84678 85.8425 7.82974C86.7388 8.80746 87.4862 10.1076 87.6744 11.5466C88.0951 14.5716 86.9897 16.6082 86.5062 19.4942C86.2684 20.8835 85.9444 22.0552 85.4609 22.8337C84.9723 23.6148 84.2275 24.0263 82.879 23.9949C81.4626 23.9529 80.0828 23.8612 78.7475 23.7328C78.1386 18.8678 78.6325 16.5794 79.1525 14.2439C79.9156 11.8088 79.5576 10.43 78.2353 5.92935C79.6098 6.0709 81.0132 6.16526 82.4609 6.21506L82.4583 6.21769ZM80.9295 20.6292C81.3999 20.7052 81.8782 20.7812 82.3564 20.852C82.7745 20.9149 83.1012 20.8022 83.3468 20.5296C83.5925 20.2596 83.744 19.7904 83.8303 19.1718C84.2196 16.0578 85.4975 14.2465 84.9801 11.2662C84.8834 10.6816 84.6456 10.2151 84.3346 9.91624C84.0236 9.6148 83.6421 9.44967 83.2083 9.42345C82.7092 9.392 82.2126 9.35792 81.7187 9.31598C83.2318 13.8664 80.8616 15.3343 80.9295 20.6266V20.6292Z" fill="#F9FBFC" />
                        <path d="M91.6125 6.12856C92.2266 10.3671 92.3965 13.2767 91.8373 16.0316C91.4192 18.7393 90.2772 21.295 91.5263 23.7065C90.596 23.7878 89.663 23.856 88.7301 23.9084C87.3791 20.8546 88.6961 18.375 89.1352 15.7983C89.7336 13.1875 89.4514 10.4824 88.5576 6.21769C89.5559 6.19409 90.5751 6.16002 91.6099 6.12594L91.6125 6.12856Z" fill="#F9FBFC" />
                        <path d="M94.9105 7.35268C95.6814 6.27535 96.9462 5.66985 98.5455 5.70392C100.145 5.73538 101.446 6.27011 102.314 6.99095C103.176 7.72489 103.521 8.68427 103.38 10.2098C103.048 13.5309 102.674 17.0643 102.34 19.6725C102.178 20.8913 101.566 21.7144 100.728 22.2649C99.8783 22.8179 98.9114 23.0801 97.5838 23.219C96.2667 23.3474 95.0568 23.295 94.1082 22.8284C93.1622 22.3697 92.6997 21.4733 92.88 20.2491C93.2955 17.5991 94.0533 14.7996 93.9775 11.3815C93.9462 9.81139 94.1317 8.42738 94.9105 7.3553V7.35268ZM97.3173 21.6436C98.3678 21.5571 98.9192 21.122 99.0133 20.1862C99.2511 17.6253 99.7868 14.1548 100.14 10.5663C100.252 9.26618 99.6901 8.67902 98.5769 8.78125C97.4584 8.88086 96.9619 9.63315 96.9227 10.9569C96.7685 14.5794 96.123 17.7826 95.8173 20.3933C95.718 21.3448 96.272 21.7223 97.3173 21.6436Z" fill="#F9FBFC" />
                    </g>
                    <defs>
                        <clipPath id="clip0_751_3905">
                            <rect width="103.412" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

            </Box>
        </>
    )
}

export default Oniric;